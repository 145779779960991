.Table {
  &__Cell {
    &--centered {
      text-align: center;
    }

    &--source {
      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-width: 0 14px 14px 0;
        border-color: transparent $proda-purple;
        right: 0;
        border-style: solid;
        top: 0;
      }
    }

    &--disabled {
      cursor: not-allowed;
    }

    &--flag {
      display: flex;

      span {
        margin-left: 0.5em;
      }
    }
  }
}
