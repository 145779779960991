@import '../../../../../../styles/base/_colours.scss';

.properties-modal {
  z-index: 1001;
}

.properties-grid {
  height: 65vh !important;
  z-index: 1000 !important;

  --ag-cell-height: 100% !important;

  .ag-popup {
    z-index: 1000;
  }

  .ag-root-wrapper {
    border: $proda-white;
    height: 100%;
  }

  .ag-header {
    z-index: 1000;
    display: block !important;
    background-color: transparent !important;
    border-bottom: transparent !important;
  }

  .ag-header-row-column {
    top: inherit !important;
    height: inherit !important;
  }

  .ag-header-cell {
    height: inherit !important;
    white-space: normal !important;
  }

  .ag-header-cell-label {
    display: block !important;
  }

  .ag-floating-top {
    border-bottom: transparent !important;
  }

  .bold-text {
    font-weight: bold;
    color: $text-color-dark;
    border-left: 1px solid $cell-border;
  }

  .cell-style:focus,
  .cell-style:active {
    border: 1px solid #bbc;
  }

  .ag-row .coflict {
    background-color: $danger-bg !important;
  }

  .ag-row .full-match {
    background-color: $warning-bg !important;
  }

  .ag-row-hover:not(.ag-full-width-row)::before {
    background-color: $proda-light-blue !important;
  }

  .ag-row.has-changed-selected,
  .ag-row.has-changed-selected:hover::before {
    background-color: $proda-purple-10 !important;
  }

  .ag-row.has-ignored,
  .ag-row.has-ignored:hover::before {
    background-color: $disabled-bg !important;
  }

  .ag-row-selected::before {
    background-color: $cell-selected-bg !important;
  }

  .ag-cell {
    .cell-is-editable-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .cell-is-editable-text {
      flex: 1 0 0%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .cell-is-editable-icon {
      display: flex;
      flex: 0 0 auto;
      padding: 0 0 0 4px;
      align-items: center;
      cursor: pointer;
    }
  }

  .header {
    font-weight: bold;
    display: flow !important;
    color: $text-color-dark;
    font-size: 12px;
    background-color: $proda-white !important;

    &--transparent {
      background-color: transparent !important;

      :hover {
        background-color: $proda-white !important;
      }
    }

    &--empty {
      border-left: 1px solid $cell-border;
      background-color: $cell-ignored-bg !important;

      :hover {
        background-color: $cell-ignored-bg !important;
      }
    }

    &--proda {
      border-left: 1px solid $cell-border;
      color: $text-color-light;
      background-color: $row-header-bg !important;

      :hover {
        color: $text-color-light;
        background-color: $row-header-bg !important;

        .ag-icon {
          color: $text-color-light !important;
        }
      }
    }

    &--review-issues {
      border-left: 1px solid $cell-border;
      color: $text-color-light;
      background-color: $proda-celtic-blue !important;

      :hover {
        color: $text-color-light;
        background-color: $proda-celtic-blue !important;
      }
    }
  }

  .legend-badge {
    display: inline-block;
    line-height: 18px;
    border-radius: 2px;
    font-size: 10px;
    font-weight: bold;
  }

  .compare-cell-changed {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-style: solid;
      border-width: 5px;
      border-color: $proda-neon-blue $proda-neon-blue transparent transparent;
    }
  }

  .compare-cell-conflict {
    border: 1px solid $proda-red;
  }

  .ag-row.has-changed-selected .compare-cell-changed::after {
    border-color: $proda-purple $proda-purple transparent transparent;
  }
}
