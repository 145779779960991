.AdminPanel__BodyContent {
  overflow: auto;
  height: calc(100vh - 104px);
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  .ms-Viewport {
    height: 100%;
    width: 100%;
  }
}

.AdminPanel__AgGrid {
  .ag-react-container {
    display: inline-flex;
    align-content: baseline;
  }

  .ag-header-cell-text {
    line-height: 12px;

    @include clamp-ag-header(2);
  }

  .ag-cell .ms-Checkbox {
    justify-content: center;

    .ms-Checkbox-checkbox {
      margin-right: 0;
    }
  }
}

.form-row {
  display: flex;

  label {
    flex-basis: 300px;
  }

  div {
    flex-grow: 1;
  }
}

admin-panel {
  display: content;
  width: 100%;
}

.query-tool {
  font-family: monospace;

  fieldset {
    background: #eee;
    border: 1px dashed grey;
    display: flex;

    legend {
      margin-left: 0.5em;
    }

    textarea {
      margin: 5px;
      font-family: monospace;
      width: 100%;
      background: #eee;
    }
  }

  .left-pane {
    min-width: 330px;
    padding-right: 1em;
    border-right: 1px solid grey;
    display: flex;
    flex-direction: column;

    button {
      align-self: end;
      border: 1px solid black;
      border-radius: 5px;
      background: #aaf;
      padding: 2px;
    }

    div.query-list {
      overflow: auto;
    }
  }

  .query-view {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .query-info {
      flex-basis: 200px;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;

      .query-fields {
        min-width: 100px;
        margin: 1em;
        display: grid;
        gap: 5px;
        grid-template-columns: max-content min-content;
        grid-template-rows: repeat(auto-fill, 25px);
        align-items: baseline;

        input {
          width: 300px;
        }
      }

      .query-and-button {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .query-text {
          flex-grow: 1;
        }

        button {
          flex-basis: 25px;
          background-color: #aaf;
          border-radius: 5px;

          &:disabled {
            cursor: not-allowed;
            background-color: #888;
          }
        }
      }
    }

    .query-output-table {
      flex: 1 1 200px;
      display: flex;
      overflow: hidden;

      & > div {
        overflow: auto;
        width: 100%;
        position: relative;

        table {
          position: absolute;
        }

        td,
        th {
          text-align: left;
          padding: 0 10px;
          line-height: 14px;
        }
      }

      margin-left: 3px;
    }

    .query-output-csv {
      flex: 0 1 100px;
      display: flex;
      margin-left: 3px;
    }
  }

  height: calc(100vh - 104px);
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  margin: 1em;

  .query-item {
    padding: 0.5em 1em;
    border-image: linear-gradient(
        to right,
        $proda-white 0%,
        #777 50%,
        $proda-white 100%
      )
      1;
    border-bottom: 1px solid;
    cursor: pointer;
  }

  .query-item.selected {
    background: #aaa;
  }
}

// The search company dropdown needs to overflow dialog boundaries.
// Not perfect but we can live with it for now.
// Need to build a our own modal dialog and remove this code than.
.change-company-dialog {
  .ms-Dialog-main {
    overflow: visible;

    .ms-Modal-scrollableContent {
      overflow: visible;

      .ms-Dialog-lgHeader {
        overflow: visible;

        .search-company-autocomplete {
          margin-top: 16px;
        }
      }
    }
  }
}
