.page {
  @extend %page;
  @extend %flex-column;

  &-header {
    @extend .nav-header;

    background-color: $proda-white;
    border-bottom: 1px solid $proda-black;
    justify-content: space-between;
    position: relative;
  }

  &-content {
    height: 100%;
    z-index: 100;

    &--table {
      @extend %grid-container;

      display: flex;
      overflow: auto;

      &-wrapper {
        flex: 1;

        overflow: hidden;
      }

      &-nowrap {
        padding: 0;
      }

      .split-screen {
        display: flex;
        height: 100%;
        width: 100%;
        overflow: hidden;
        gap: 8px;

        &--full-width,
        &--half-width {
          display: flex;
          flex: 1;
        }

        &--hidden {
          display: none;
        }
      }
    }
  }
}

.page.admin-page {
  max-height: max-content;
}
