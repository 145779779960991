.sidebar-table-settings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  list-style: none; // Otherwise MS Edge displays list-like bullets

  .details-static {
    flex-shrink: 0;
  }

  .details-expanding {
    flex-shrink: 1;
    flex-grow: 1;
    position: relative;

    & > .details-content {
      inset: 25px 3px 6px;
      display: flex;
      flex-direction: column;
      position: absolute; // matching padding of parent

      .search .btn-close {
        position: absolute;
        right: 5px;
      }
    }
  }

  .details-expanding[open] {
    flex-grow: 1;
  }

  .details-expanding,
  .details-static {
    border: 1px #ddd solid;
    border-radius: 5px;
    padding: 3px;
    box-sizing: border-box;

    // these two rules are just for old-edge pseudo-support. They should
    // not have an effect on browsers that implement details/summary
    display: flex;
    flex-direction: column;

    & > summary {
      height: 20px;
      cursor: pointer;
    }

    .details-edit-rr {
      margin-top: 4px;
    }
  }

  summary:focus {
    outline: none;
  }

  details {
    + details,
    + add-variables {
      margin-top: 5px;
    }
  }

  details summary {
    .buttons {
      margin-left: auto;
    }

    button {
      font-size: 12px;
      line-height: 10px;
      width: 9px;
      box-sizing: content-box;
      border: 2px #ddd;
      border-style: outset;
      border-radius: 4px;

      &:active {
        background-color: grey;
        border-style: inset;
      }
    }
  }

  .variable-search-tree-node > div.details-content {
    display: flex;
    flex-direction: column;
  }

  .virtual-list-container {
    & > div {
      height: 100%;
      width: 100%;
    }
  }
}

// Use the mixin below for the div that contains the div with the "searchableDropdown" class
// to get a pretty magnifier and a slightly different look
@mixin magnifier-searchable-dropdown-container {
  input {
    box-sizing: border-box;
    text-align: left;
    padding-left: 15px;
  }

  .with-magnifier {
    display: flex;
    align-items: center;

    &::before {
      position: absolute;
      margin-left: 0;
    }
  }
}

.sidebar-preset {
  $input-fontsize: 12px;

  div.searchableDropdown {
    $item-height: 20px;
    $item-separator-width: 1px;

    height: 34px;

    div.searchableDropdown-list-parent {
      max-height: calc(25 * (#{$item-height} + #{$item-separator-width}));

      #preset-search-input--list {
        border-width: 0;

        li {
          position: relative;
          text-align: left;
          justify-content: left;
          min-height: $item-height;
          line-height: $item-height;
          border-bottom-width: $item-separator-width;
          padding-left: 10px;

          &.built-in-preset {
            font-weight: bold;
          }

          &:last-child {
            border-bottom-width: 0;
          }
        }
      }
    }
  }

  .list-block--item-value-text {
    font-size: 14px;
  }

  .list-block--item-value > input {
    width: 100%;
    height: 16px;
    font-size: $input-fontsize;
  }

  &-container {
    margin-top: 10px;
    padding: 13px 10px 10px;
    box-sizing: border-box;
    border: 1.5px solid $tertiary-color;
    border-radius: 10px;
    background: #f9f9f9;

    .Button--sidebar-small {
      border: 1px solid $primary-color;
    }
  }

  :not(.Button--disabled).Button--danger.Button--delete {
    background-color: $proda-white;
    border-color: $proda-red;
    color: $proda-red;
    fill: $proda-red;

    &:hover {
      background-color: $proda-red;
      color: $text-color-light;
      fill: $text-color-light;
    }
  }

  .Button--disabled {
    svg {
      fill: currentcolor !important;
    }
  }

  &__name-input {
    margin-bottom: 15px;
    position: relative;

    .list-block--item {
      margin: 0;
    }

    li {
      cursor: initial;
    }

    .error-label-container {
      position: absolute;
      top: 12px;
    }
  }

  &__button-bar {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;

    & > div {
      margin-left: 4px;
      flex: 1 1 0;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__button-edit-textual {
    margin-top: 5px;
    margin-bottom: 5px;

    button {
      font-size: 10px;
    }
  }
}

add-variables {
  $input-fontsize: 12px;

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .search {
    margin-left: 10px;

    input {
      padding-left: 31px;
      font-size: 12px;
      width: 200px;
    }
  }

  .result-container {
    // overflow-y: hidden;
    // overflow-x: auto;
    flex-grow: 1;
    flex-shrink: 1;
    margin-top: 5px;
    border-top: 1px solid #f3f2f1;
    user-select: none;
    display: flex;
    flex-direction: column;

    & > .variable-search-tree-node {
      flex-grow: 0;
      flex-shrink: 1;
      transition: flex-grow 0.5s;
      position: relative;
      overflow-y: hidden; // without this, there is flickering to the
      // transition above. flex-grow transition
      // position-absolute child does not communicate
      // instantly, apparently.
      & > div {
        position: absolute;
        height: calc(100% - 20px);
        left: 0;
        padding-left: 5px;
        right: 0;
        margin: 1px 0;
      }

      & > div:not(.virtual-list-container) {
        overflow-y: auto;
      }

      div:focus {
        outline: none;
      }
    }

    & > .variable-search-tree-node[open] {
      flex-grow: 1;
    }
  }

  details.variable-search-tree-node > summary::before {
    font-size: 15px;
    line-height: 15px;
    margin-right: 2px;
    content: '►';
  }

  details.variable-search-tree-node[open] > summary::before {
    content: '▼';
  }

  // special-casing for firefox :(
  @supports (-moz-appearance: meterbar) {
    details.variable-search-tree-node > summary::before {
      font-size: 18px;
      line-height: 18px;
    }

    details.variable-search-tree-node[open] > summary::before {
      content: '▾';
    }

    details.variable-search-tree-node:not([open]) > summary::before {
      content: '▶';
    }
  }

  .variable-search-tree-node {
    summary {
      font-weight: bold;
    }

    .variable-search-tree-node {
      summary {
        font-weight: normal;
      }

      .variable-search-tree-node {
        summary {
          font-weight: 400;
        }
      }
    }

    font-size: 12px;
    flex-direction: column;
    margin: 1px 0;
    padding-left: 5px;

    summary {
      &::-webkit-details-marker {
        display: none;
      }

      height: 16px;
      cursor: pointer;
      margin-bottom: 2px;
      display: flex;
      align-items: baseline;

      span.label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      span.count {
        margin: 0 3px;
      }
    }
  }

  @include magnifier-searchable-dropdown-container;

  .add-variables-checkbox-container {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 2px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    input {
      vertical-align: middle;
      margin-right: 3px;
      cursor: pointer;
    }
  }

  .variable {
    flex-grow: 1;
    background-color: rgb(245 245 245);
    font-size: 12px;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 2px;
    cursor: default;

    &-rentroll {
      display: flex;
      flex-direction: row;
    }

    &-shared,
    &-tc,
    &-only-tc {
      display: flex;
      padding-top: 3px;
      padding-bottom: 2px;
    }

    &-rentroll,
    &-shared,
    &-only-tc {
      flex-direction: row;
      justify-content: center;
    }

    &-tc {
      flex-direction: column;
    }

    &-only-tc,
    &-shared {
      .varcolumn__label {
        max-width: 188px;
        margin-right: auto;
      }
    }

    &-not-file-related .varcolumn__label {
      color: #828282;
    }

    &__label {
      margin-left: 32px;
      text-decoration: underline;
      max-width: 188px;
    }

    &__column-container {
      & > div {
        display: flex;
        flex-direction: row;
        flex: 1 0 0;
      }

      display: flex;
      flex-direction: column;
    }
  }

  .varcolumn,
  .variable-shared,
  .variable-rentroll,
  .variable-only-tc {
    cursor: pointer;

    &:hover {
      background-color: $proda-dusty-pink !important;
    }

    transition: background-color 1s cubic-bezier(0, 0.8, 0.2, 1);
  }

  .varcolumn {
    &__checkmark {
      font-weight: bold;
      flex: 0 0 16px;
    }

    &__label {
      flex-grow: 1;
      flex-shrink: 1;
      text-align: left;
    }
  }

  .compact {
    .variable__label {
      text-decoration: none;
    }

    .variable__column-container {
      flex-direction: row;
    }

    .varcolumn {
      &-comparison {
        background-color: rgb(220 220 255 / 30%);
      }

      &-delta {
        background-color: rgb(200 200 255 / 30%);
        border-left: 1px;
        border-right: 1px;
        border-style: dotted;
        border-color: #aaa;
        transition: none;
      }

      &-output {
        background-color: rgb(220 220 255 / 30%);
      }
    }
  }

  .custom-column-drag-source {
    font-family: agGridBalham;
    font-size: 16px;
    cursor: grab;
  }

  // prevent duplicated text input "x" clear button on search input in old edge
  input::-ms-clear {
    display: none;
  }
}

/* Tooltip container */
.tooltip-below {
  position: relative;

  // display: inline-block;
  // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip-below .tooltiptext {
  visibility: hidden;
  background-color: $proda-dusty-pink;
  color: $text-color-dark;
  text-align: center;
  padding: 5px 2px;
  border-radius: 6px;
  pointer-events: none;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;

  // top: 125%;
  top: 7px;
  width: 190px;
  margin-left: 2px;

  // left: 50%;
  // margin-left: -60px;
  left: -200px;
  right: 0;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip-below .tooltiptext::after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: -15px;
  border-width: 10px;
  border-style: solid;
  border-color: $proda-dusty-pink transparent transparent;
  box-shadow: $shadow;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-below:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.react-tooltip {
  &.proda-tooltip-style {
    color: $text-color-dark;
    background-color: $proda-dusty-pink;
    font-size: 10px;
    line-height: 1.3;
    font-weight: normal;
    z-index: 9999;
    padding: 0.25rem 0.5rem;
    box-shadow: $shadow;
  }

  &.trigger-auto-format-tooltip {
    width: 172px;
  }

  &.limited-width-tooltip {
    max-width: 300px;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  &.tooltip-preserve-whitespace {
    white-space: pre-line;
  }
}
