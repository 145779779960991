.search {
  position: relative;

  * {
    pointer-events: auto;
    cursor: pointer;

    &:focus {
      border: none;
      outline: none;
    }
  }

  .search-box input {
    height: unset;

    &::placeholder {
      color: $proda-white-150;
    }
  }

  &-box {
    border: 1px solid $primary-color;
    display: flex;
    justify-content: flex-start;

    &::before {
      content: '\e986';
      font-family: Icomoon;
      line-height: 1.2;
      font-size: 1em;
      color: $primary-color;
      padding: 5px;
      margin-left: 15px;
    }

    input {
      margin: 2px;
      width: 250px;
      border: none;
      border-radius: 0;
      cursor: text;
    }

    .btn-close {
      padding-top: 3px;
      border: none;
      background: transparent;
      color: $primary-color;
      cursor: pointer;

      &::before {
        content: '\EA0F';
        font-family: Icomoon, serif;
        line-height: 1.2;
      }
    }

    &--inline {
      margin-top: 5px;
    }
  }

  .list-group--centered {
    @media (height <= 700px) {
      height: 100px;
    }

    overflow-y: auto;
  }
}
