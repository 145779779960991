.link {
  @include resetButton;

  cursor: pointer;

  &-small {
    font-size: 14px;
  }

  &--hyperlink {
    color: $accent-color;
  }

  &-black {
    color: $text-color-dark;
  }

  &:disabled {
    color: $text-color-disabled;
    cursor: not-allowed;
  }
}
