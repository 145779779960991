@import 'fonts/icomoon';

/*
In React, you can use `<Text pattern="..." /> which will set the right font.

For elm/static files, just as a temp compatibility, use the following classes.

DO NOT COPY THESE `font-family: ...` lines anywhere else.

Either:

- Just add the class
- Extend the class in your own class e.g. `@extend .compat-body;`
*/
.compat-body {
  font-family:
    'Noto Sans',
    arimo,
    arial,
    helvetica,
    -apple-system,
    sans-serif !important;
}

.compat-heading {
  font-family:
    'DM Sans',
    -apple-system,
    'Helvetica Neue',
    helvetica,
    arial,
    sans-serif !important;
}
