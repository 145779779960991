.inner-html {
  font-size: 12px;

  blockquote {
    position: relative;
    padding-left: 13px;

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      border-left: 3px solid;
      height: 100%;
      left: 0;
    }
  }

  ul,
  ol {
    list-style-position: inside;
  }

  li > ul,
  li > ol {
    margin-left: 15px;
  }

  ul li {
    padding: 0 1px;
  }

  p {
    line-height: 14px;
    margin-bottom: 0.35em;
    display: block !important;
  }

  h1,
  h2,
  h3,
  h4 {
    color: $text-color-dark;
    margin: 0 0 0.25em !important;
  }

  &.color-white {
    *,
    h1,
    h2,
    h3,
    h4 {
      color: $text-color-light;
    }
  }
}
