$anim-len-in: 0.1s;
$anim-len-out: 0.3s;

@mixin button-color($name, $bg, $border, $fg) {
  &--#{$name} {
    background-color: $bg;
    border-color: $border;
    color: $fg;
    fill: currentcolor;
    transition:
      background-color $anim-len-out ease 0s,
      border-color $anim-len-out ease 0s,
      color $anim-len-out ease-in-out 0s;

    /** TODO -- For now don't replace colors for excel/tick icon **/
    svg:not(#btn-excel, #btn-cross, #btn-delete-bin, #btn-plus) path,
    svg:not(#btn-excel, #btn-cross, #btn-delete-bin, #btn-plus) g {
      fill-opacity: 0;
      stroke: $fg;
      transition: stroke $anim-len-out ease 0s;
    }

    svg#btn-plus g,
    svg#btn-plus path {
      fill-opacity: 1;
      stroke-opacity: 0;
      fill: $fg;
    }
  }
}

@mixin isDisabled {
  cursor: not-allowed;
  background: $disabled-bg;
  color: $text-color-disabled;
  border: 1px solid $text-color-disabled;

  &:hover,
  &:active {
    background: $disabled-bg;
    color: $text-color-disabled;
    border-color: $text-color-disabled;
    box-shadow: none;
  }

  ::placeholder {
    color: $text-color-disabled;
  }
}

.Button {
  place-content: center center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  padding: 8px 16px;
  height: 32px;

  &:disabled,
  &[aria-disabled='true'],
  &:disabled:hover,
  &[aria-disabled='true']:hover {
    @include isDisabled;
  }

  /** Colors **/
  @include button_color(
    'primary',
    $primary-color,
    $primary-color,
    $text-color-light
  );
  @include button_color(
    'secondary',
    $proda-white,
    $primary-color,
    $text-color-dark
  );
  @include button_color(
    'loading',
    $disabled-bg,
    $disabled-bg,
    $text-color-disabled
  );

  &--danger {
    color: $proda-red-120;
    background: $proda-white;
    border: 1px solid $proda-red-120;

    &:hover {
      color: $proda-white;
      background: $proda-red-120;
      border: 1px solid $proda-red-120;
      box-shadow: $shadow;
    }

    &:active {
      color: $proda-white;
      background: $proda-red-120;
      border: 1px solid $proda-red-120;
    }
  }

  &--inline {
    display: inline-flex;
    width: unset;
  }

  &--small {
    .Button__text + .Icon,
    .Icon + .Button__text {
      margin-left: 6px;
    }

    .Icon {
      height: 16px;
      width: 16px;
    }
  }

  &--large {
    // @include ft_large;

    height: 100%;
    width: 100%;

    .Button__text + .Icon,
    .Icon + .Button__text {
      margin-left: 3px;
    }

    .Icon {
      bottom: 1px;
      height: 20px;
      width: 20px;
    }
  }

  &--sidebar-small {
    padding: 8px 0;

    .Button__text + .Icon,
    .Icon + .Button__text {
      margin-left: 4px;
    }

    .Icon {
      height: 16px;
      width: 16px;
    }
  }

  &__first-line {
    display: flex;
  }

  &--multiline {
    flex-direction: column;
  }

  &__second-line {
    font-size: 10px;
  }

  &--butticon {
    padding: 4px;
    border: none;
    width: 32px;

    .Button__text {
      display: none;
    }
  }

  &--loading {
    cursor: default;
  }

  &:active:not(.Button--disabled, .Button--danger),
  &--active:not(.Button--disabled, .Button--danger) {
    background-color: $secondary-color;
    border-color: $proda-black;
    color: $text-color-dark;
    transition:
      background-color $anim-len-in ease 0s,
      border-color $anim-len-in ease 0s,
      color $anim-len-in ease-in-out 0s;

    svg:not(#btn-excel, #btn-cross) path,
    svg:not(#btn-excel, #btn-cross, #delete-bin) g {
      stroke: $proda-white;
      transition: stroke $anim-len-in ease 0s;
    }
  }
}
