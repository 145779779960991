.breadcrumb {
  overflow: hidden;
  margin-bottom: -8px;

  &__list,
  &__list-item {
    @extend %resetList;
  }

  &__list {
    display: flex;
    align-items: flex-end;
  }

  &__list-item {
    @extend .compat-heading;

    color: $text-color-dark;
    font-size: 12px;
    line-height: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;

    &--active {
      color: $text-color-dark;
      font-weight: 400;
      font-style: italic;
    }

    &::before {
      content: '/';
      margin-right: 6px;
      margin-left: 6px;
      color: $tertiary-color;
    }

    a {
      &:link,
      &:visited {
        color: $text-color-dark;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
