@font-face {
  font-family: icomoon;
  src: url('fonts/icomoon.eot?4urpa9');
  src:
    url('fonts/icomoon.eot?4urpa9#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?4urpa9') format('truetype'),
    url('fonts/icomoon.woff?4urpa9') format('woff'),
    url('fonts/icomoon.svg?4urpa9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icomoon-'],
[class*=' icomoon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icomoon !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* line-height: 1; */

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-dropdown-arrow::before {
  content: '\e913';
}

.icomoon-loading-ico::before {
  content: '\e912';
}

.icomoon-left_column::before {
  content: '\e910';
}

.icomoon-circle-up::before {
  content: '\ea41';
}

.icomoon-circle-right::before {
  content: '\ea42';
}

.icomoon-circle-down::before {
  content: '\ea43';
}

.icomoon-circle-left::before {
  content: '\ea44';
}

.icomoon-drawer::before {
  content: '\e95c';
}

.icomoon-cog::before {
  content: '\e994';
}

.icomoon-printer::before {
  content: '\e907';
}

.icomoon-outline_box::before {
  content: '\e900';
}

.icomoon-tick_complete::before {
  content: '\e901';
}

.icomoon-audit::before {
  content: '\e902';
}

.icomoon-file::before {
  content: '\e903';
}

.icomoon-top_row::before {
  content: '\e904';
}

.icomoon-edit::before {
  content: '\e905';
}

.icomoon-arrow_down-::before {
  content: '\e911';
}

.icomoon-338::before {
  content: '\e911';
}

.icomoon-pin_sharp_circle::before {
  content: '\e906';
}

.icomoon-search_left::before {
  content: '\e924';
}

.icomoon-red-arrow-down::before {
  content: '\e908';
  color: #e71f1f;
}

.icomoon-green-arrow-up::before {
  content: '\e909';
  color: #4bd0a5;
}

.icomoon-arrow_down::before {
  content: '\e90a';
}

.icomoon-menu::before {
  content: '\e90b';
}

.icomoon-home::before {
  content: '\e90c';
}

.icomoon-home2::before {
  content: '\e90d';
}

.icomoon-home3::before {
  content: '\e90e';
}

.icomoon-coin_dollar::before {
  content: '\e93b';
}

.icomoon-coin_euro::before {
  content: '\e93c';
}

.icomoon-coin_pound::before {
  content: '\e93d';
}

.icomoon-clock::before {
  content: '\e94e';
}

.icomoon-calendar::before {
  content: '\e953';
}

.icomoon-user::before {
  content: '\e971';
}

.icomoon-spinner1::before {
  content: '\e980';
}

.icomoon-zoom-in::before {
  content: '\e987';
}

.icomoon-bin::before {
  content: '\e9ac';
}

.icomoon-list::before {
  content: '\e9ba';
}

.icomoon-plus::before {
  content: '\ea0a';
}

.icomoon-info::before {
  content: '\ea0c';
}

.icomoon-cross::before {
  content: '\ea0f';
}

.icomoon-tick::before {
  content: '\ea10';
}

.icomoon-indent-increase::before {
  content: '\ea7b';
}

.icomoon-ctrl::before {
  content: '\ea50';
}

.icomoon-search::before {
  content: '\e986';
}

.icomoon-files-empty::before {
  content: '\e925';
}

.icomoon-upload3::before {
  content: '\e9c8';
}

.icomoon-download3::before {
  content: '\e9c7';
}

.icomoon-warning::before {
  content: '\ea07';
}

.icomoon-users::before {
  content: '\e972';
}

.icomoon-pie-chart::before {
  content: '\e99a';
}

.icomoon-balance_scale::before {
  content: '\e90f';
}
