@import '../../../../../styles/base/colours';

.file-drop-dragging {
  position: fixed;
  opacity: 0.7;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;

  /* `pointer-events: none` ensures drag events are only triggered
    for the div handling them, which helps reason about code that
    handles those events */
  * {
    pointer-events: none;
  }
}

.upload-text {
  font-size: 5rem;
  position: absolute;
  z-index: 10000;
  display: flex;
  color: $text-color-dark;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.7;
}
