.toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;

  &__switch {
    position: relative;
    display: flex;
    width: 32px;
    height: 16px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .toggle__slider {
        background-color: $proda-green;
        border-color: $proda-green;
        box-shadow: $shadow;

        &::before {
          background-color: $proda-white;
          border-color: $proda-white;
          transform: translateX(14px);
          right: 4px;
        }
      }
    }
  }

  &__slider {
    width: 100%;
    height: 100%;
    background-color: $proda-white;
    border: 1px solid $proda-black-10;
    transition: transform 0.4s;
    border-radius: 11px;

    &::before {
      position: absolute;
      content: '';
      height: 12px;
      width: 12px;
      left: 4px;
      bottom: 4px;
      top: 2px;
      background-color: $proda-black;
      border: inherit;
      transition: transform 0.4s;
      border-radius: 50%;
    }
  }

  &__label {
    font-size: 12px;
    font-weight: 700;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;

    svg.Icon {
      width: 22px;
      height: 22px;
      margin-right: 0;
    }

    &--selected {
      color: $proda-orange;
    }

    &--unselected {
      color: $accent-color;
    }
  }
}
