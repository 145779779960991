.ts-page {
  width: 100%;
  date-picker {
    border-radius: 0.25rem;
    border: 1px solid $dark;
    font-size: 0.75rem;
    height: 24px;
  }

  date-picker {
    height: 24px;
    width: 100px;
    font-weight: normal;
  }

  date-picker.invalid {
    border: 1px solid $danger-bg;
  }
}

.ts-page date-picker {
  &::part(input-field) {
    background-color: transparent;
    margin: 0;
    height: 100%;
  }

  input {
    border: none !important;
  }
}

.toggle-wrapper {
  display: flex;
  align-items: center;
  padding: 4px 16px;

  .toggle {
    display: inline-flex;
  }
}
