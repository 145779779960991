/**
 * Edge doesn't support webcomponents/shadow-dom and by inspection required some extra styles to be applied to match the same behaviour of firefox/chrome.
 * In the case of the date picker web component, additional styling was required.
 * The following styles only get applied when web components aren't supported and hence a `<vaadint-date-picker/>` element exists in the global DOM.
 */
vaadin-date-picker-overlay {
  z-index: 9000;
}

date-picker * {
  --lumo-text-field-size: 23px;

  background-color: transparent;
  border: none;
  font-size: 12px;
  font-weight: normal;
  height: 23px;
  padding: 0;
  width: 100px;
}

date-picker[theme*='review'] [part$='button'].date-picker {
  position: absolute;
  top: 1px;
  right: 0;
  z-index: 9000;
}
