%resetList {
  margin: 0;
  padding: 0;
  list-style: none;
  clear: both;
}

%resetTxt {
  margin: 0;
  padding: 0;
}

select {
  background: transparent;
  padding: 1px 5px;
  padding-right: 15px;
  font-size: 12px;
  border: 1px solid $proda-white-150;
  appearance: none;
  background-image: url('../icons/select-menu-icon.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 15px 15px;
}

select::-ms-expand {
  display: none;

  /* remove default arrow in IE 10 and 11 */
}

/* Target Internet Explorer 9 to undo the custom arrow. Ref for hacks:
http://browserbu.gs/css-hacks/media-min-width-0-backslash-0
https://stackoverflow.com/questions/8004765/css-9-in-width-property
*/

@media screen and (width >= 0\0) {
  select {
    background: none\9;
    padding: 5px\9;
  }
}

@mixin link() {
  // Not ideal but we have to force links styles for now
  a,
  a:link,
  a:hover,
  a:active,
  a:visited {
    color: $primary-color !important;
  }
}

@mixin scroll() {
  overflow: hidden auto;
}

@mixin select-disabled() {
  user-select: none;
}

@mixin resetInput() {
  // not ideal, but we need to override because it inherits from app.input
  border: none;
  background: transparent;
  outline: none !important;
  box-shadow: none !important;
}

@mixin resetButton() {
  border: none;
  background: transparent;
  outline: none !important;
  box-shadow: none !important;
  margin: 0;
  padding: 0;
}

//GoogleMaps
.gm-style-iw + button {
  display: none;
  visibility: hidden;
}

// react-tooltip
body {
  // overwrite default .9
  --rt-opacity: 1;
}

// uikit-tooltip html fixes
.uikit-tooltip__body,
.react-tooltip > span {
  ul,
  ol {
    padding-left: 1.3em;
  }
  > :is(p, ul, ol) {
    margin-bottom: 0.3em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Typography helpers
.mark {
  &-strong {
    font-weight: bold;
  }
}

/* Use Noto in commandbar */
#commandbar-home [class*='commandbar-'],
#commandbar-home [id*='commandbar-'],
#commandbar-home [style*='font-family: Avenir'],
[data-testid='commandbar-input']::placeholder {
  font-family:
    'Noto Sans',
    arimo,
    arial,
    helvetica,
    -apple-system,
    sans-serif !important;
}
