body.specPage {
  background-color: rgb(245 251 234 / 15%); //prowhite but not as strong
  text-align: center;

  img {
    margin: auto;
  }

  blockquote {
    width: 600px;
    margin: auto;
    text-align: left;
    padding: 20px 20px 10px;

    cite {
      text-align: right;
    }
  }

  div {
    width: 750px;
    margin: auto;
    text-align: left;
    background-color: rgb(0 0 0 / 2%);
  }

  div.featureBox {
    padding: 10px;

    h2 {
      text-align: left;
    }

    ul.definitions {
      list-style-type: none;

      li {
        p.def {
          margin-left: 40px;
        }
      }
    }
  }
}
