$outline: 1px;

// Map page filters
.filters {
  font-size: 0.9rem;

  h2 {
    font-size: 1.2rem;
  }

  &__multi {
    &--all {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      ul {
        padding: 0;
        margin: 0;
      }

      li {
        list-style: none;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
      }

      label {
        height: 2em;
        line-height: 1.5em;
      }
    }

    &--category {
      &-button {
        width: 1.3em;
        margin: 0 0.5em 0 0;

        button {
          display: inline-block;
          border: none;
          border-radius: 2px;
          color: $text-color-light;
          background-color: $proda-black;
          height: 20px;
          width: 20px;
          cursor: pointer;

          &::before {
            content: '\EA50';
            font-family: Icomoon, serif;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 100%;
            width: 100%;
            line-height: 1;
          }
        }

        .open {
          @include flipHorizontal;
        }

        .close {
          @include flipVertical;
        }
      }

      &-options {
        &--input {
          width: 100%;
          background-color: $proda-white;
          border-radius: 2px;
          outline: none;
          line-height: 1.5em;
          margin: 0 0 1.5em;

          &:focus {
            border-style: solid; // avoid shorthand because styles.elements
            border-width: 2px;
            border-color: $proda-black;
            box-shadow: none !important;
          }
        }

        &--list {
          overflow-x: hidden;
          width: 100%;
          margin: 0 0 0.5em;

          label {
            input {
              visibility: hidden;
              margin: 0;
              cursor: pointer;
            }
          }

          &-checkbox {
            border: 2px solid $dark;
            border-radius: 3px;
            position: relative;
            width: 15px;
            height: 15px;
            user-select: none;
            margin: 0 0 0.5em;

            input,
            span {
              position: absolute;
            }

            span,
            &.checked::before {
              top: -$outline;
            }

            span {
              width: 12.2em;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              padding: 0 0 0 1.2em;
              line-height: 1em;
            }

            &.unchecked {
              background-color: $proda-white;
            }

            &.checked {
              &::before {
                content: '\ea10';
                font-family: Icomoon, serif;
                line-height: 1.2;
                color: $text-color-dark;
                font-size: 0.8em;
                position: absolute;
              }
            }
          }
        }

        &--content {
          width: 13em;
          padding: 0.5em 0 0.5em 2.3em;

          input {
            &::placeholder {
              padding: 0.5em;
            }
          }
        }
      }
    }
  }
}
