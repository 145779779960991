// Infowindow
.gm-style {
  .gm-style-iw {
    text-align: center;

    div {
      overflow: hidden !important;
    }

    h3 {
      margin: 0;
      padding: 0;
      font-size: 1rem;
      font-weight: bold;
    }

    + div {
      visibility: hidden;
    }
  }
}

// TODO: seems like we do not use clusters anymore
// .gm-cluster {
//   text-align: center;
//   vertical-align: middle;
//   background-color: $color-primary;
//   color: $text-color-light;
//   font-size: 12px;
//   font-weight: bold;
//   border-radius: 50%;
//   box-shadow: 0 0 4px 0 black;
// }

// .gm-cluster::before {
//   content: '';
//   display: inline-block;
//   height: 100%;
//   vertical-align: middle;
// }
