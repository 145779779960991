.custom-tooltip {
  position: absolute;
  background-color: $proda-dusty-pink;
  color: $text-color-dark;
  border-radius: 1px;
  box-shadow: 5px 5px 10px 0 rgb(0 0 0 / 40%);
  max-width: 40em;
  padding: 5px;
  transition: opacity 1s;
  box-shadow: $shadow;

  &.ag-tooltip-hiding {
    opacity: 0;
  }

  p.original-label {
    font-weight: bold;
    margin-bottom: 0.5em;
  }
}
