div.fileStatus-page {
  margin-top: 60px;
  padding: 20px;
  height: calc(100% - 60px);
  text-align: center;
  vertical-align: middle;

  h1 {
    margin-top: calc(20vh - 60px);
  }

  img {
    margin: 60px;
  }
}
