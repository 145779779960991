// GF 27 May 2023: Shouldn't a warning be yellow ($color-warning)
//                 and errors be red ($danger-bg)?

// TODO: this is probably a validation error message for the elm add/edit property form in PA page.
//
.warning {
  font-size: 0.8rem;
  margin: 0.3em 0;
  color: $tertiary-color; // TODO: would like to replace with $color-warning
  font-weight: normal;

  &-link {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
  }
}
