@mixin listHeight($size) {
  ul {
    max-height: $size;
    overflow: hidden auto;
  }
}

.list {
  @extend %resetList;

  color: $text-color-dark;

  &--row {
    display: flex;
  }

  &-group {
    h2 {
      margin: 0;
      padding: 0;
    }

    ul {
      @extend %resetList;

      border: 1px solid $primary-color;
      font-size: 0.85rem;
    }

    li {
      cursor: pointer;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;

        &::before {
          content: '(';
        }

        &::after {
          content: ')';
        }
      }
    }

    &-item {
      font-size: 0.9rem;
      line-height: 1.85em;
      padding: 0 1em;
      border-bottom: 1px solid $proda-white-130;

      // Add the "list-group-item-with-anchor" if you want the anchor inside to occupy the full space of the "li"
      &-with-anchor {
        padding: 0;

        & > a {
          width: 100%;
          height: 100%;
          display: block;
          padding: 0 1em;
        }
      }

      &:last-of-type {
        border: none;
      }

      &:hover {
        background-color: $hover-bg;
      }

      &--active {
        background-color: $select;
        color: $text-color-dark;
      }

      &--primary {
        color: $text-color-dark;
      }

      &--secondary {
        color: $text-color-dark;
      }
    }

    &--small {
      @include listHeight(5.8em);

      line-height: 1.5em;
    }

    &--large {
      @include listHeight(16em);

      @media (height <= 800px) {
        @include listHeight(14em);
      }
    }

    /* TODO: this is dead. we do not use --centered modifier */
    &--centered {
      text-align: center;
      font-size: 0.8rem;
      border: 1px solid $primary-color;
    }

    &--overlay {
      position: absolute;
      background-color: $proda-white;
      box-shadow: 1px 1px 5px $primary-color;
    }
  }

  // TODO: this is probably dead code
  .active {
    background-color: $primary-color; // GF 27 May 2023: not sure why this is blue.
    // We have a $color-warning var, but it's not blue.
    color: $text-color-light;

    &-dark {
      background-color: $primary-color;
      color: $text-color-light;
      font-weight: bold;

      button {
        color: $text-color-light;
      }
    }
  }

  &-block {
    &--help-icon {
      text-transform: none;
      color: $text-color-dark;
      font-size: 17px;
      font-weight: normal;
      font-family: Icomoon;
      line-height: 1.2;
    }

    @extend %resetList;

    button {
      float: right;
    }

    &--flex-item {
      display: flex;
    }

    &--container {
      display: flex;
      flex-direction: column;
      margin-right: auto;
    }

    &--item {
      @extend %resetList;

      margin-bottom: 26px;
      flex-shrink: 0;

      &-label {
        font-size: 12px;
        text-transform: uppercase;
        display: block;
        color: $text-color-dark;
        font-weight: bold;
        line-height: 1.5em;
      }

      &-value {
        display: flex;
        flex-direction: row;
        font-size: 16px;
        color: $text-color-dark;
        font-weight: bold;
        line-height: 1em;

        :first-child {
          flex-grow: 1;
        }

        &-text {
          display: inline-block;

          //https://github.com/philipwalton/flexbugs#1-minimum-content-sizing-of-flex-items-not-honored
          min-width: 0;
          word-wrap: break-word;
        }

        input,
        textarea {
          @include resetInput;

          border-radius: 0;
          border-bottom: 1px solid $primary-color !important;
          line-height: 2em;
          margin: 0;
          padding: 0;

          &:focus,
          &:active {
            padding: 0;
            margin: 0;
            border: none;
          }
        }

        .btn {
          // Don't reset padding left and right, because it overrides .btn-small
          padding-top: 0;
          padding-bottom: 0;
          line-height: inherit;
          align-self: flex-start;
        }
      }
    }
  }
}
