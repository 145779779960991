body {
  #logged-out-modal .uikit-modal__panel {
    width: 520px;
  }
  div.modal-dialog {
    width: 520px;

    &.trigger-auto-format-dialog {
      width: 712px;
      max-height: none !important;

      #modal-title {
        font-size: 14px !important;
      }

      #modal-body {
        padding: 12px !important;
        #modal-description {
          margin: auto;
          > p {
            font-size: 12px !important;
            line-height: 15px !important;

            &.warning-message {
              margin-top: 32px;
              display: flex;
              align-items: center;
              gap: 8px;

              svg {
                flex-shrink: 0;
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }

      img {
        width: 100%;
        max-width: 680px;
        height: auto;
        margin: 32px 0;
      }
    }

    form {
      display: flex;
      flex-direction: column;

      .message {
        #modal-title {
          display: flex;
          justify-content: center;
          flex-direction: column;
          font-size: 0.9rem;
          padding: 16px;
          border-bottom: 1px solid $proda-white-150;
          background-color: $proda-neon-blue-10;
          color: $text-color-dark;

          h2 {
            font-size: 1rem;
          }

          h3 {
            font-size: 0.8rem;
            font-weight: normal;
          }
        }

        div#modal-body {
          font-size: 0.8rem;
          line-height: 1.5em;
          padding: 16px;
          border-bottom: 1px solid $proda-white-150;
          display: flex;
          align-items: center;
          max-height: 100vh;
          min-height: 82px;
          overflow-y: auto;

          .ms-Spinner {
            margin: auto;
          }

          span.dialog-action-highlight {
            color: $proda-red;
          }

          div#modal-description {
            div.confirm-delete-line {
              display: flex;
              justify-content: space-between;
              align-items: center;

              span.confirm-delete-line-text {
                white-space: nowrap;
                margin-right: 15px;
              }
            }

            input {
              padding: 4px 8px;
              width: 100%;
            }

            .review-error-header {
              margin-bottom: 15px;
            }

            .review-error-body {
              list-style-position: inside;
            }
          }
        }
      }
    }

    .button-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 16px;

      button,
      input[type='submit'],
      input[type='button'] {
        height: 32px;
        font-size: 0.8rem;
        margin: 0;
        border-radius: 4px;
        min-width: 100px;
        width: auto;
        padding: 0 12px;
        white-space: nowrap;

        &:not(:last-of-type) {
          margin-right: 8px;
        }

        &.dangerous-button:enabled {
          background-color: $proda-white;
          border: 1px solid $proda-red;
          color: $proda-red;

          &:hover {
            background-color: $proda-red;
            color: $proda-white;
          }
        }
      }
    }

    .deleted-entity-kind {
      text-transform: capitalize;
    }

    .preset-textarea {
      flex-grow: 1;
      resize: none;
    }
  }

  .modal-background div.preset-info {
    width: 800px;
    max-width: 90vw;
    height: 90vh;
    max-height: 90vh;
    display: flex;
    flex-direction: column;

    > form {
      flex-grow: 1;

      .content {
        padding: 0 5px 5px;
        flex: 1 1 0;
        overflow: auto scroll;

        table {
          width: 100%;

          th {
            padding: 5px;
            position: sticky;
            top: 0;
            background: $proda-white;

            // This fixes a 1px gap between the top of the .content element and
            // the sticky header in google chrome on hidpi devices.
            // @see https://bugs.chromium.org/p/chromium/issues/detail?id=693412
            &::before {
              content: ' ';
              display: block;
              position: absolute;
              top: -1px;
              left: 0;
              right: 0;
              height: 1px;
              background: $proda-white;
            }
          }

          tbody {
            font-size: 12px;

            td {
              height: 15px;
            }

            tr:nth-child(even) {
              background-color: #ddd;
            }

            tr:nth-child(odd) {
              background-color: #eee;
            }
          }
        }
      }
    }
  }

  div.modal-background .modal.edit-preset-dialog {
    width: 60vw;
    height: 90vh;
    max-height: 90vh;
    display: flex;
    flex-direction: column;

    form {
      flex-grow: 1;

      textarea {
        font-family: monospace;
        font-size: 10px;
      }
    }
  }

  div.modal-background .modal.preset-save-as,
  .preset-rename {
    width: 520px;
    max-width: 90vw;
  }

  div.modal-background .modal.preset-save-to {
    width: 520px;
    max-width: 90vw;

    #modal-body #modal-description .db-item {
      &:not(:last-child) {
        margin-bottom: 4px;
      }

      label {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        input {
          width: unset;
        }
      }
    }
  }
}
