// ************** styles for Layout component START ************** //
.layout {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);

  /* ***** HEADER ***** */
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-shrink: 0;
    height: 46px;
    padding: 0 15px;
    background-color: $proda-white;
    border-bottom: 1px solid $dark;
  }

  &__breadcrumbs {
    display: flex;
    flex-grow: 1;
  }

  &__actions {
    display: flex;
    gap: 8px;
  }

  /* ***** BODY ***** */
  &__body {
    display: flex;
    flex-grow: 1;
  }

  &__main {
    flex-grow: 1;
    height: calc(100vh - 104px);

    // TODO: check how ag-grid layout component handles borders. Borders should be define by layout wrappers.
  }
}

.flex {
  display: flex;
}

// ************** styles for Layout component END ************** //

$headerHeight: 60px;

%grid-container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
}

%flex-column {
  display: flex;
  flex-direction: column;

  & > .flex-grow {
    flex-grow: 1;
  }
}
%flex-row {
  display: flex;
  flex-direction: row;

  & > .flex-grow {
    flex-grow: 1;
  }
}
%page {
  flex-grow: 1;

  & > div:not(.page-header) {
    @extend %flex-column;

    flex-grow: 1;

    & > div:not(.nav-page) {
      flex-grow: 1;
    }
  }
}

$LAYOUT-HEADER-HEIGHT: 60px;

.layout-header {
  height: $LAYOUT-HEADER-HEIGHT;
}

.layout-main {
  @extend %flex-row;

  height: calc(100vh - $LAYOUT-HEADER-HEIGHT);
}

.frame-container {
  @extend %flex-column;

  height: 100vh;
}

.col-auto {
  padding: 0;
}

.nav-header {
  display: flex;
  height: 46px;
  flex-shrink: 0;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0 15px;
}

.navbar-container {
  @extend .nav-header;

  height: $headerHeight;
  background-color: $light;
  border-bottom: 1px solid $dark;

  /* This z-index is exceptionally high, necessary to ensure it appears above the sidebar
  shadows for steps in elm idprop, idcol, and find-table, without making structural changes.
  In the future, we should address this by properly setting the stacking context. */

  a {
    height: inherit;
  }

  svg {
    width: 140px;
    height: inherit;
    padding-top: 0.12em;
  }

  .NavBar {
    &__Separator {
      flex-grow: 0;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.flex-align-start {
  align-items: flex-start;
}

.flex-baseline {
  align-items: baseline;
}

.flex-end {
  justify-content: flex-end;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

// TODO: probably dead code
#tablePresets {
  padding-top: 1em;

  h3 {
    display: inline;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: $text-color-dark;
  }
}

.sidebar,
.sidebar-right {
  h3,
  h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    padding: 0;
    margin: 0;
    color: $text-color-dark;
    display: inline;
    text-transform: uppercase;
  }
}

.sidebar-exports {
  height: 100%;
  overflow-y: auto;
}

.sidebar {
  box-sizing: content-box;

  &-right {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: $proda-white;
    box-sizing: border-box;
    width: 356px;
    min-width: 356px;
    padding: 10px;
    filter: drop-shadow(2px 6px 4px $dark);

    & .sidebar-filters {
      padding: 0.5em 0 0.5em 1em;
    }

    h1 {
      color: $text-color-dark;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      text-transform: uppercase;
    }

    h2 {
      margin: 0;
      margin-bottom: 10px;
      color: $text-color-dark;
      font-size: 18px;
      font-weight: bold;
      line-height: 19px;
      text-transform: uppercase;
    }

    &.withshadow {
      margin: 0;
    }

    &.padded {
      margin: 0 0 0 1em;
    }
  }

  &-checkbox {
    text-align: center;
    font-family: Verdana, serif;
    font-size: 12px;
  }

  &-comparison {
    h1 {
      color: $text-color-dark;
      text-transform: uppercase;
      font-weight: bold;
    }

    h2 {
      color: $text-color-dark;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
      text-transform: uppercase;
    }

    .list--row {
      width: 230px;
      justify-content: space-between;
    }
  }

  &-table {
    &-block {
      margin-bottom: 20px;
    }

    &-link {
      display: block;
      margin-top: 0.5em;
      cursor: pointer;
    }

    // TODO: this seems to be a dead code.
    // There are some .Button elements, however they do not referencing this class.
    // Leaving it here just in case.
    .list {
      .Button {
        margin: 5px 0 25px;
        font-size: 10px;
        border: 1px solid $secondary-color;
        height: 34px;
      }
    }

    .list-group--overlay {
      background-color: #f7f7f7;
      width: 234px;
      z-index: 1;
    }

    .TableRentrolls {
      display: flex;
      flex-flow: column wrap;
      font-size: 12px;
      text-align: left;
      width: 100%;
      white-space: nowrap;
      margin-bottom: 2px;

      .rr-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        &:not(:first-child) {
          margin-top: 2px;
        }

        .rr-row-title {
          display: flex;
          vertical-align: bottom;
          text-transform: uppercase;
          font-weight: bold;

          // Align text header to bottom of "cell"
          flex-direction: column;
          justify-content: flex-end;
          flex-basis: 33%;
          flex-shrink: 0;
        }

        .rr-row-contents {
          display: flex;
          justify-content: left;
          flex-grow: 2;
          min-width: 0;

          & > span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .rent-roll-cycle-buttons {
          .disabled {
            color: $text-color-disabled;
            border-color: $text-color-disabled;
          }

          :not(.disabled) {
            cursor: pointer;
          }

          font-size: 16px;
          flex-grow: 1;
          margin-left: 5px;
          margin-right: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;

          svg {
            border: 1px solid black;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            fill: currentColor;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
            box-sizing: border-box;
          }
        }
      }

      a,
      .Button--currency {
        font-size: 12px;
        border: none;
        background: none;
        // TODO: this might not indicate this element are interactive. Looks almost like a text.
        color: $text-color-dark;
        text-align: left;
        padding: 0;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        width: 160px;
      }

      .DateContainer {
        position: relative;
        border: 1px solid $primary-color;
        box-sizing: border-box;
        border-radius: 3px;
        width: 105px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        .DateDropdown {
          outline: none;
          font-size: 12px;
          font-weight: bold;
          margin: 0;
          cursor: default;

          &.cursor {
            cursor: pointer;
            margin-left: 3px;
          }
        }

        .searchDropdown-arrow {
          padding: 0;
          margin: 0 5px;
        }
      }

      .DateDropdown-list {
        z-index: 50;
        background: $proda-white;
        position: absolute;
        top: 20px;
        width: 103px;
        border: 1px solid $dark;
        border-top: none;
        box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
        max-height: 276px;
        overflow: auto;
      }

      li {
        font-size: 12px;
        font-weight: normal;
        padding-left: 10px;
      }

      .dropdown-item {
        height: 23px;
        display: flex;
        align-items: center;

        &:hover {
          background-color: $hover-bg;
          color: $text-color-dark;
        }
      }

      .hide {
        display: none;
      }
    }
  }
}

.inactive {
  display: none;
  visibility: hidden;
}
