#policies {
  flex: 1 1 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 3rem;
  align-items: center;
  gap: 2rem;

  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 12px;
  }
  p,
  li {
    font-size: 14px;
  }

  .policy {
    max-width: 600px;
    border: 1px solid #020734;
    border-top-width: 5px;
    padding: 2rem;
    border-radius: 0.5em;

    .policy-body {
      margin-top: 1rem;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  ul,
  ol,
  blockquote,
  pre,
  table,
  dl,
  hr {
    margin: 0.65em 0 0.35em;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  ol,
  ul {
    padding-left: 1.5em;
  }

  li {
    margin: 0.4em 0 0.2rem;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
