/** This only applies to List-Prop, since we no longer
have other asset-list pages */
.assets-list-page {
  @extend %grid-container;

  /** Make sure pencil icon is right alined inside
  the cell in List-Prop */
  div[col-id='ag-Grid-AutoColumn'] {
    .ag-group-value {
      width: 100%;
    }
  }

  .delete {
    color: $proda-red-120;
  }
}

// This is important for the map on property list and doesn't seem to
// hurt the other maps.
#map-container {
  height: 100%;
  width: 100%;
  position: relative;

  google-map {
    display: block;
    width: 100%;
    height: 100%;
  }
}
