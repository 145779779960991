.searchableDropdown {
  $input-height: 34px;
  $border-width: 2px;
  $item-height: calc(0.8 * $input-height);
  $item-line-height: $item-height;
  $padding-amount: 5px;

  position: relative;
  left: 0;
  right: 0;
  box-sizing: border-box;
  height: $item-height;
  vertical-align: middle;
  cursor: pointer;
  background-color: $proda-white;
  border: $border-width solid $primary-color;
  border-width: $border-width;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;

  ul {
    font-size: 12px;
  }

  &-input,
  &-selectedItem {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    padding: $padding-amount;
    padding-left: 15px;
    padding-right: calc(25px + #{$padding-amount});
    width: 100%;
    height: 100%;
    font-family: inherit;
    outline: none;
    box-shadow: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: bold;
  }

  &-input {
    z-index: 1;
    border: none;
  }

  &-input:focus {
    background-color: transparent;
  }

  &-disallowSearching input.searchableDropdown-input {
    color: transparent;
    background-color: transparent;
    cursor: pointer;
  }

  _:-ms-lang(x),
  &-disallowSearching input.searchableDropdown-input:focus {
    // The text cursor blinks on IE and Edge even with transparent colours.
    // Sadly, setting `visibility: hidden` seems to trigger Blur for Chrome,
    // so we keep it only for IE and Edge, where that does not happen
    visibility: hidden;
  }

  &-selectedItem {
    // GF 16/06/2023
    // This causes the Preset input field content to overflow in Table settings.
    // Removing `display: flex;` would fix it, but other areas will be affected
    // (E.g., the "display currency", "not older than" and "output date" in the
    // secondary navigation, need `display: flex;`.
    // Creating a specific class below to target this element. Line 157
    // This is not an ideal fix.
    z-index: 0;
    display: flex;
    align-items: center;
  }

  & > div.searchableDropdown-list-parent {
    position: absolute;
    box-sizing: border-box;
    top: ($input-height - $border-width);
    width: 100%;
    z-index: 5;
    margin: 0;
    padding: 0;
    max-height: calc(6 * (#{$item-line-height} + #{$border-width}));
    line-height: 1.6;
    background-color: $proda-white;
    vertical-align: middle;
    overflow-y: auto;
    list-style: none;
    font-size: inherit;
    font-family: inherit;
    border: 1px solid $primary-color;
    border-top: none;

    li {
      text-align: center;
    }
  }

  &-item {
    position: relative;
    min-height: $item-height;
    word-break: break-word;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border-bottom: 1px solid $proda-white-130;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-color-dark;

    &:hover {
      background-color: $hover-bg;
    }

    &--selected {
      background-color: $select;
    }
  }

  &-clearTextButton {
    position: absolute;
    right: 0;
    top: -2px;
    width: auto;
    z-index: 2;
  }

  &-blank &-clearTextButton {
    display: none;
  }
}

.searchDropdown-arrow {
  display: flex;
  align-items: center;
  padding-right: 15px;
  z-index: 3;
  cursor: pointer;
}

.searchDropdown-arrow::before {
  content: '\E913';
  font-family: Icomoon;
  line-height: 1.2;
  font-size: 6px;
}

.sidebar-preset__add-variables li.searchableDropdown-item {
  min-height: 26px;
}

.sidebar-preset__template-search {
  .searchableDropdown.searchableDropdown-closed.searchableDropdown-blank {
    .searchableDropdown-selectedItem {
      display: unset;
      padding-top: 7px;
    }
  }
}
