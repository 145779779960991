explorer-page {
  display: block;
  height: 100%;
  width: 100%;
  overflow: auto;
}

.dashboard-frame-container {
  border: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  iframe {
    height: 100%;
    width: 100%;
  }

  #export-header {
    display: none; /* Display only when printing */
    width: 29.7cm !important;

    .proda-logo-bar {
      display: flex;
      align-items: center;
      background-color: #0018ea;
      height: 1cm;
      padding: 0 0.3826cm;

      img {
        fill: $proda-white;
        height: 0.8cm;
        width: 2.857144cm;
      }
    }

    .export-toolbar {
      display: flex;
      align-items: center;
      height: 1cm;
      padding: 0 0.5cm;

      .dashboard-name {
        font-weight: normal;
        font-size: 12px;
        flex-basis: 33.33%;
      }

      .selectors {
        display: inline-flex;
        gap: 8px;

        .selector {
          width: 108px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 2px;
        }

        .icon {
          width: 0.875rem;
          height: 0.875rem;
        }

        .value-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .label {
          font-weight: 400;
          font-size: 12px;
          color: $text-color-dark;
        }

        .value {
          font-weight: bold;
          font-size: 12px;
          color: $text-color-dark;
          flex: 1;
          text-align: center;
        }
      }
    }
  }

  #export-dashboard-overlay {
    display: none;
  }

  &.dashboard-export-in-progress {
    #export-dashboard-overlay {
      display: flex;
      background-color: $proda-white;
      width: 100%;
      height: 100%;
      align-items: center;
      font-size: 32px;
      justify-content: center;
      position: absolute;
      z-index: 2; // Above the iframe
    }

    iframe {
      // Setting `visibility: hidden` sadly stops dashboard elements from reflowing inside the iframe
      // for some reason.
      // So we use absolute positioning with z-index tricks to hide this when exporting. Absolute
      // positioning also helps not resize the parent
      height: 28.27cm; // A4 paper (Landscape) proportions in case someone wants to print the export
      width: 40cm;
      position: absolute;
    }

    #export-header {
      display: block;
    }
  }
}
