/** Page Global **/

.review {
  font-size: 1rem;
  box-sizing: border-box;
  border-collapse: separate;
  width: 100%;
}

review-page > div {
  display: flex;
}

/** AgGrid Table **/

.review-table:not(.ag-dnd-ghost) {
  overflow: auto;
  flex-grow: 1;
}

.review-table:not(.ag-dnd-ghost) > div {
  width: 100%;
}

.Upload__Review__Top {
  .review-header {
    height: 46px;
    width: 100%;
    padding: 0 15px;
    background-color: $proda-white;
    border-bottom: 1px solid $proda-black;

    .review-summary {
      display: flex;
      align-items: center;
      margin-left: auto;
      min-width: 320px;
    }
  }
}

.Upload__Review__NavSteps {
  position: absolute;
  top: 60px;
  width: 360px;
  z-index: 3;
}

.Upload__Review__NavButtons {
  bottom: 0;
  box-shadow: none;
  height: 64px;
  position: absolute;
  width: 360px;
  z-index: 3;

  .nav-sidebar--fixed-bottom {
    bottom: 0;
    position: absolute;
    width: 100%;
  }
}

review-page {
  .Upload__Review {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__Content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  .EnMasse {
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    padding: 12px 3px;
    display: grid;
    grid-template-columns: 10px 3fr auto auto 4fr;
    grid-auto-flow: row;
    gap: 4px 4px;
    overflow: auto;
    max-height: 450px;

    &__Entry {
      &__ChangeStatus {
        grid-column-start: 1;
        height: 90%;
        margin-top: auto;
        margin-bottom: auto;
        border: 2px solid white;
        border-radius: 4px;

        &--user {
          background-color: $proda-purple;
        }

        &--proda {
          background-color: $primary-color;
        }
      }

      &__Dropdown {
        height: 32px;
        grid-column-start: 2;

        &__TitleItem {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }

        &__Item {
          white-space: nowrap;
        }
      }

      &__Arrow {
        height: 32px;
        line-height: 32px;
        grid-column-start: 3;
      }

      &__Count {
        text-align: center;
        font-size: 12px;
        height: 32px;
        line-height: 32px;
        grid-column-start: 4;
      }

      &__Original {
        // Currently for some ag-grid cell styles
        // we center align the text
        // however in the en masse widget
        // this does not look good
        text-align: left !important;
        font-size: 12px;
        grid-column-start: 5;
        max-width: 150px;
        padding-top: 8px;
        padding-right: 4px;
      }
    }
  }

  .EnMasseSpec {
    border: 1px solid #cbcbcb;
    border-radius: 5px;
    padding: 12px;
    display: grid;
    grid-template-columns: 10px 4fr;
    grid-auto-flow: row;
    gap: 4px 6px;

    hr {
      border: none;
      grid-column: 1 / -1;
      margin-bottom: 10px;
      width: 100%;
      height: 1px;
      background-color: #cbcbcb;

      &:last-child {
        display: none;
      }
    }

    &__ChangeStatus {
      grid-column-start: 1;
      height: 100%;
      margin-top: auto;
      margin-bottom: auto;
      border: 2px solid white;
      border-radius: 4px;

      &--user {
        background-color: $proda-purple;
      }

      &--proda {
        background-color: $primary-color;
      }
    }

    &__Entry {
      grid-column-start: 2;

      &__Legend {
        display: grid;
        grid-template-columns: auto auto 3fr;
        column-gap: 6px;
        padding-top: 5px;

        &__Arrow {
          transform: rotate(90deg);
          grid-column-start: 1;
          font-size: 12px;
        }

        &__Count {
          text-align: center;
          font-size: 12px;
          grid-column-start: 2;
        }

        &__Original {
          text-align: left !important;
          font-size: 12px;
          grid-column-start: 3;
          padding-top: 1px;
          line-break: anywhere;
          max-width: 200px;
        }
      }
    }
  }

  .SidebarInfoBox__section__col {
    margin-bottom: 0;

    select {
      border-radius: 2px;
      border: 1px solid lightgrey;
      font-size: 12px;
      height: 25px;
      max-width: 160px;
      overflow: hidden;
      padding-top: 3px;
      text-overflow: ellipsis;
    }
  }
}

.Review__Card {
  .ColumnLabel {
    font-size: 14px;
    flex-grow: 1;
  }

  .ColumnActiveLabel {
    font-size: 14px;
    flex-grow: 0;
    margin-left: 16px;

    &--active {
      color: $proda-green;
    }

    &--inactive {
      color: $proda-red;
    }
  }

  .ColumnActiveToggle {
    margin-left: 8px;
  }

  button[aria-checked='true'] {
    background-color: $success-bg;
  }

  h3 {
    color: inherit;
    margin-top: 0;
  }

  p {
    font-size: 12px;
    color: $text-color-dark;
  }

  #cell-info-values {
    font-size: 12px;
  }

  &__error {
    display: list-item;
    margin-left: 10px;
  }
}

.Review__Card.Review__Card.Review__Card.CellInfo {
  .CellInfo__Value {
    // ag-grid cell rendering is reused, but we want it to always be left justified
    text-align: left !important;
    margin-bottom: 8px;
    font-size: 12px;
  }

  .CellInfo__OriginText {
    background-color: #efefef;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    margin-bottom: 5px;
  }

  tr {
    padding-top: 5px;
  }

  th {
    padding-right: 12px;
    text-align: right;

    /* This leaves the TH wide enough to fit on one line. Needed because the
         TD has width 100%. */
    white-space: nowrap;
  }

  th[colspan] {
    /* These have the corresponding value below them, not to the side, so
         right align looks weird. */
    text-align: left;
  }

  td {
    width: 100%;
  }

  table {
    margin-bottom: 5px;
  }
}

/* Set some reasonable defaults for the DnD element. */
.ag-dnd-ghost {
  background-color: $proda-white;
  border-color: black;
  font-size: 12px;
}

.review-table {
  display: flex;
  align-items: center;

  .yellow-overlay {
    background-color: rgb(255 245 156 / 50%) !important;
  }

  /* Disabled globally for output tables */
  .ag-side-buttons {
    display: unset;
    width: 22px;
  }

  .ag-column-panel {
    /* suppressPivotMode on the gridOptions does not seem to work */
    .ag-pivot-mode-panel {
      display: none;
    }

    .ag-column-group-icons {
      margin-left: 2px;
    }
  }

  .ag-side-bar {
    .ag-filter-toolpanel-header {
      background-color: transparent;
      color: black;
    }

    .ag-group-title-bar {
      background-color: transparent;
      color: black;
    }

    .ag-filter-toolpanel-search {
      color: black;
    }
  }
}

.review-table,
.idrow-page,
.idcol-page {
  .ag-header-cell:not(.ag-column-hover):first-of-type:not(
      .ag-header-cell-moving
    ):hover {
    background-color: $primary-color;
  }
}

.review-table .ag-root {
  .ag-popup-editor {
    border: none;

    .usetype-editor {
      svg {
        height: 14px;
        width: 14px;
      }

      input {
        margin-left: 8px;
      }
    }

    .ag-rich-select-list {
      max-height: 200px;
      height: 100%;
    }
  }

  /** Cell Editors **/
  input[type='text'],
  input[type='number'] {
    border: none;
    height: 21px;
  }

  select {
    border: none;
    height: 21px;
  }

  input,
  select,
  date-picker {
    position: absolute;
    background-color: #f5f5f5;
    left: 0;
    padding-left: 3.5px;
    padding-bottom: 3.5px;
    font-size: inherit;
    width: 100%;
    outline: none;
    z-index: 99;
  }

  select {
    width: 100%;
    height: 100%;
  }

  date-picker {
    width: 100%;
    height: 100%;
  }

  /** Icon **/
  .review-header-icon-open::before,
  .review-header-icon-closed::before {
    display: block;
    font-size: 0.8em;
    content: '\0e90a';
    font-family: Icomoon;
    line-height: 1.2;
    color: $text-color-light;
    z-index: 10;
    cursor: pointer;
    margin: 0 4px 0 0;
  }

  .review-header-icon-open::before {
    transform: rotate(270deg);
    transform-origin: 50% 50%;
  }

  .ag-cell {
    color: $text-color-dark;
  }

  .review-cell--user,
  .review-cell--proda {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      border-style: solid;
      border-width: 5px;
    }
  }

  .review-cell--inactive {
    background-color: #f2f2f2;
    color: #aeaeae;
  }

  .review-cell--saving {
    font-style: italic;
  }

  .review-cell--left {
    text-align: left;
  }

  .review-cell--center {
    text-align: center;
  }

  .review-cell--right {
    text-align: right;
  }

  .review-cell--unoccupied {
    color: #999;
  }

  @mixin review-cell-style($bgColor) {
    background-color: $bgColor;
    border: 1px solid $proda-black;

    // Reset border-left if the next sibling has the same classes to avoid double borders
    & + .review-cell--error,
    & + .review-cell--warning {
      border-left: none;
    }
  }

  // Double the .ag-cell classes to increase specificity and avoid using !important
  .ag-cell.ag-cell.review-cell--error {
    @include review-cell-style($danger-bg);
  }

  // Double the .ag-cell classes to increase specificity and avoid using !important
  .ag-cell.ag-cell.review-cell--warning {
    @include review-cell-style($warning-bg);
  }

  .review-cell--conflict {
    font-weight: bold;
    color: $proda-red;
  }

  .review-cell--user::after {
    border-color: $proda-purple $proda-purple transparent transparent;
  }

  .review-cell--proda::after {
    border-color: $accent-color $accent-color transparent transparent;
  }

  .ag-header-group-cell {
    .ag-header-group-cell-label {
      flex-direction: row-reverse;

      .ag-header-group-text {
        font-size: 12px;
        flex-basis: 0;
        flex-grow: 1;
      }

      /* If the header icon exists, then the main/variable column exists
      *  making it redundant to show the group label text.
      *  The large flex-grow value hides the group text by making
      *  the icon element dominate all the space.
      */
      .ag-header-expand-icon {
        flex-grow: 1000;
      }

      .ag-icon {
        text-align: right;
      }
    }
  }

  // keeps combo columns aligned down when toggle active/inactive state
  .ag-header-row.ag-header-row-column {
    display: flex;

    .review-header--option {
      height: calc(100% - 10px) !important;
      align-self: end;
    }
  }

  .ag-header {
    .ag-cell-label-container {
      display: block;
      height: 100%;
    }

    .ag-header-group-cell.ag-header-group-cell-no-group.ag-focus-managed,
    .ag-header-group-cell.ag-header-group-cell-with-group.ag-focus-managed {
      &:focus::after {
        content: none;
      }
    }

    .ag-header-cell-text {
      @include ag-header-word-wrap;
    }

    .ag-header-component {
      span.ag-header-sort {
        position: absolute;
        top: 5px;
        right: 5px;
      }
    }

    .ag-header-cell {
      background-color: $primary-color;
      color: $text-color-light;
      font-weight: bold;
      height: 100% !important;
      top: auto !important;

      &.review-header--user-added,
      &.review-header--user-added:hover {
        color: $text-color-light;
        background-color: $proda-purple;

        &.review-header--inactive {
          background-color: lighten($proda-purple, 28%); // #d3aee3
        }
      }

      &.review-header--inactive,
      &.review-header--inactive:hover {
        background-color: $disabled-bg;
      }

      &:focus::after {
        content: '';
        border: 1px solid $proda-white;
        height: calc(100% - 6px);
        left: 0;
        margin: 3px;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: calc(100% - 3px);
      }

      // "option" columns are an internal name for "candidate" columns
      &.review-header--option {
        &.review-header--source {
          color: $text-color-dark;
          background-color: $secondary-color;

          &.review-header--inactive {
            color: $text-color-disabled;
            background-color: $secondary-color;
          }
        }
      }

      .ag-header-cell-label {
        align-items: start;
        display: flex;
        justify-content: space-between;
        overflow-wrap: break-word;
        text-overflow: ellipsis;
        white-space: pre-line;
      }

      .padding-y-5 {
        padding: 5px 0;
      }
    }

    .ag-header-cell.ag-header-cell.ag-header-cell:focus::after {
      border: none;
    }

    .ag-header-container {
      background: rgb(189 195 199 / 50%);
    }
  }

  // Show normal cursor for totals row (differs for output pages).
  .ag-row.ag-row-pinned .ag-cell {
    cursor: default;
  }

  // Hide extra border for totals row
  .ag-floating-top {
    border-bottom: none;
  }

  .ag-row.ag-row-selected.ag-row-selected:not(.ag-row-hover) {
    background-color: rgb(33 164 243 / 15%);
  }

  .ag-cell.ag-cell-range-selected-1:not(.ag-cell-focus),
  .ag-cell.ag-cell-range-selected-1:not(.ag-cell-inline-editing),
  .ag-cell.ag-cell-range-selected-2:not(.ag-cell-focus) {
    background-color: rgb(33 164 243 / 25%);
  }
}

/******* CONTROL **********/

/** Column Info **/

.SidebarInfoBox {
  // TODO: seems dead
  // &--outline {
  //   border: 1px solid lighten($proda-navy, 50%);
  //   padding: 1.5em 1em 1em;
  //   height: 23em;
  //   margin-top: 15px;
  // }

  &__heading {
    align-items: baseline;
    color: $text-color-dark;
    font-weight: 700;
    font-size: 0.8rem;
    display: flex;

    &--primary {
      text-transform: uppercase;
      margin-bottom: 1em;
    }

    &--secondary {
      font-size: 1rem;
      justify-content: space-between;
    }

    &--tertiary {
      font-size: 1rem;
      margin-bottom: 1em;
    }
  }

  &__subheading {
    font-size: 0.8rem;
    line-height: 1.5em;
    color: $text-color-dark;
    font-weight: 400;

    &-strong {
      font-weight: bold;
      color: $text-color-dark;
    }
  }

  &__description {
    max-height: 150px;
    overflow: auto;
  }

  &__section {
    &-paragraph {
      margin: 2px 0 0;
      padding: 0;
      color: $text-color-dark;
      font-size: 0.8rem;
    }

    &__row {
      display: flex;
      align-items: center;
      height: 30px;

      > span:first-of-type {
        color: $text-color-dark;
        font-weight: bold;
        display: inline-block;
        margin-right: 10px;
      }

      &--original-value {
        max-height: 4.5em;
        overflow-y: auto;
      }
    }

    &__col {
      color: $text-color-dark;
      margin-bottom: 1em;

      p {
        margin: 0;
        padding: 0;
      }
    }
  }

  &__list {
    @extend %resetList;

    font-size: 1rem;
    margin: 3px 0 24px;

    &--item {
      &-clipped {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: 50%;
      }
    }
  }

  &__control {
    select {
      padding: 1em;
      font-size: 0.8rem;
      border-radius: 0;
    }
  }
}

.review.control--centered {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.review.control__title {
  font-size: 16px;
  display: inline-block;
  position: relative;
  width: 100%;
  color: $text-color-dark;
  text-transform: uppercase;
  font-weight: 700;
}

/******* STANDARDISED **********/

.review.summary {
  font-size: 1rem;
  width: 100%;
  z-index: 500;
  position: relative;
  height: 60px;
}

/** Icon **/
.review.summary__arrow-icon::before {
  font-size: 0.8em;
  content: '\0e90a';
  font-family: Icomoon;
  line-height: 1.2;
  position: absolute;
  top: 0;
  right: 0;
  color: $text-color-light;
  z-index: 10;
  cursor: pointer;
  margin: 0.75em;
}

.review.summary__arrow-icon--flipped::before {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

/** Cell Mods **/

.AddColumn {
  &__Select {
    padding-top: 10px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.PExpr {
  &__Block {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  &__Row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  &__ReviewVar {
    font-weight: 700;
    margin: 2px 0;
    line-height: 1.8;
    border-radius: 3px;
    padding: 0 7px;
    background-color: $proda-white;
    color: $text-color-dark;
    border-color: $proda-black;
    border-width: 1px;

    &--root {
      background-color: $proda-black;
      color: $text-color-light;
    }
  }

  &__IdColVar {
    margin: 2px 0;
    font-weight: 700;

    &__IdColLeft,
    &__IdColRight {
      line-height: 1.8;
      padding: 0 7px;
      border-color: $proda-black;
      border-width: 1px;
    }

    &__IdColLeft {
      color: $text-color-light;
      background-color: $primary-color;
      border-color: $primary-color;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &__IdColRight {
      color: $text-color-dark;
      background-color: $secondary-color;
      border-color: $secondary-color;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &__UserAdded {
      color: $text-color-light;
      background-color: $proda-purple;
      border-color: $proda-purple;
    }
  }

  &__List {
    margin-left: 20px;

    &__ListItem {
      /* This stops margin collapsing */

      padding-bottom: 0.01px;

      & > .PExpr__Block {
        margin-left: 0;
      }
    }
  }

  &__OpBefore {
    margin-left: 7px;
  }

  &__OpSum {
    min-width: 14px;
  }

  &__OpSum + &__Block {
    margin-left: 0;
  }
}

.ColumnOrigin__Expr {
  font-family: monospace;
  font-size: 12px;
  width: max-content;

  & > .PExpr__Block {
    margin-left: 0;
  }
}

.duns__menu-list {
  .duns__option {
    &.duns__option--is-selected {
      background-color: $secondary-color;

      p {
        color: $text-color-dark;
      }
    }
  }
}

// custom CSS for the Review external Dropdowns
.ms-Callout-container {
  .ms-ComboBox-option,
  .ms-Dropdown-item {
    color: $text-color-dark;

    &[aria-selected='true'] {
      background-color: $select;
    }

    &:hover {
      background-color: $hover-bg;
    }
  }
}
