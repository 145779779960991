#accept-policies-body {
  // We don't want a scrollbar in here, but if for some odd reason the contents don't fit (screen too small),
  // we need to make sure the user can scroll down
  overflow-y: auto;
}

#accept-policies-modal {
  position: absolute;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 480px;
  width: 560px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 50%);
  font-size: 12px;
  line-height: 18px;
}

#accept-policies-policies-list {
  height: 356px;
  overflow: auto scroll;
  margin: 30px 30px 3px;
  padding: 0;
}

.privacy-policy-title {
  width: 95%;

  h1 {
    float: left;
  }

  h2:last-child {
    float: right;
  }
}

.privacy-policy-contents {
  clear: both;
  padding-top: 5px;
  width: 95%;
}

#accept-policies-bottom-shadow {
  height: 5px;
  background: linear-gradient(
    0deg,
    rgb(0 0 0 / 20%) 0%,
    rgb(255 255 255 / 100%) 100%
  );
}

#accept-policies-buttons {
  height: 34px;
  padding: 26px 30px 20px;
  border-radius: 0 0 10px 10px;

  button {
    width: 151px;
    height: 34px;
    border-width: 1px;
  }

  #accept-policies-button {
    float: left;
  }

  #decline-policies-button {
    float: right;
  }
}
