.fx-rates-grid {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  color: $text-color-dark;
  color: var(--ag-foreground-color, $text-color-dark);
  font-size: 12px;
  line-height: normal;

  &.ag-theme-balham-output .ag-header-cell {
    height: inherit !important;
    padding: 8px;
    background-color: $proda-white;
    color: $text-color-dark;

    &:hover {
      background-color: $proda-white !important;
    }
  }
}

.period-select {
  width: 150px;

  .uikit-field-label {
    justify-content: center;
  }

  .uikit-icon {
    color: $accent-color;
  }

  .uikit-custom-input {
    border: none;
    margin-bottom: 0;

    &:focus {
      box-shadow: none;
      border: none;
    }

    &__content {
      padding: 0;
      margin: 0 auto;
    }

    &__value {
      color: $text-color-dark;
      font-weight: bold;
    }
  }

  .uikit-dropdown__content {
    .uikit-dropdown__select-item__content {
      justify-content: center;

      .uikit-text-field {
        &__input {
          text-align: center;
          background-color: $proda-white;

          &:focus {
            box-shadow: none;
            border-width: 1.5px;
          }
        }
      }
    }
  }
}
