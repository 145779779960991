#commandbar-home {
  // tabs
  #commandbar-search-tab-container {
    button[role='tab'] {
      color: $text-color-dark;

      &[aria-selected='true'] {
        background-color: $select;
      }

      &:hover {
        background-color: $hover-bg;
      }
    }
  }

  // list items
  #commandbar_menu_list {
    // selected/hovered item
    div[role='option'][aria-selected='true'] {
      .commandbar-bar-tagl6k {
        background-color: $hover-bg;
      }
    }
  }
}
