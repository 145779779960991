.container {
  &-fixed {
    // this won't work as we have a new side navbar now
    // width: calc(100vw - #{$uploadSidebar});
    height: calc(100vh - 60px);

    &--top {
      height: 46px;
      display: flex;
      align-items: center;
      font-size: 0.9rem;
    }

    .btn-close {
      float: right;
    }
  }
}

.row {
  &--fluid {
    display: flex;
    flex-direction: row;

    &-left {
      width: 100%;
      justify-content: flex-end;

      span {
        align-self: center;
      }
    }
  }
}

.content {
  margin: 0;
  padding: 0;
}

.table {
  &-container {
    @supports (-ms-ime-align: auto) {
      overflow: hidden;
    }

    overflow: auto;
    flex: 1;
  }

  &-content {
    height: 100%;
  }
}
