.uikit-autocomplete.currency-selector {
  width: 108px;

  .uikit-text-field__input {
    padding: 0 1.125rem 0 1.875rem;
  }
}

.uikit-selector.output-selector,
.uikit-selector.comparison-selector,
.uikit-selector.recency-selector {
  width: 108px;

  .uikit-dropdown__content {
    width: 130%;
    transform: translateX(15%);
  }

  .uikit-text-field {
    &__field {
      > div:first-child svg,
      > div:last-child svg {
        width: 13.1333px;
        height: 10.5px;
      }
    }

    &__input {
      padding: 0 1.125rem 0 1.09375rem;
    }
  }
}

.uikit-selector.output-selector,
.uikit-selector.comparison-selector {
  .uikit-dropdown__content {
    .uikit-dropdown__select-item {
      .uikit-dropdown__select-item__content {
        justify-content: space-between;
      }

      .uikit-icon {
        color: $accent-color;
      }
    }
  }
}

.uikit-selector.output-selector,
.uikit-selector.recency-selector,
.uikit-selector.comparison-selector,
.uikit-autocomplete.currency-selector {
  .uikit-text-field {
    &__label {
      margin-bottom: 0.25rem;
      justify-content: center;
    }

    &__field {
      > div:first-child {
        left: 0.125rem;
      }

      > div:last-child {
        right: 0.125rem;
      }
    }

    &__input {
      border: none;
      font-weight: bold;
      background: transparent;
      height: 1.1rem;
      border-radius: 2px;

      &::placeholder {
        font-size: inherit;
      }

      &:focus,
      &:focus-visible {
        box-shadow: none;
      }

      &--error {
        border: none;
        box-shadow: none;
        color: $proda-red;
      }
    }
  }
}

.uikit-selector.recency-selector {
  .uikit-dropdown__content {
    .uikit-dropdown__select-item__content {
      justify-content: center;
    }
  }
}

// ############# SELECTOR STYLES FOR ELM ELEMENTS- START #############

.nav-selectors {
  display: flex;
  position: relative;

  .callout-element {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 100%);
    min-width: 300px;
    pointer-events: none;
  }

  &__inner {
    display: flex;
    gap: 4px;
    margin: auto;
  }

  .warning-icon-wrapper {
    cursor: pointer;
    margin-top: auto;
    width: 16px;
    height: 18px;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .currency-container {
    // NOTE: for some reason the currency-selector web component rendered
    // in the currency-container div has a strange height of 21px.
    // add height for now but need to figure out why this is happening
    height: 36px;
    display: flex;
  }

  .elm-selector-wrapper {
    display: flex;
    gap: 2px;

    &--error {
      .uikit-text-field__input {
        color: $proda-red-120;
        border: 1px solid $proda-red-120;
      }
    }

    &__date-arrow {
      font-size: 14px;
      font-weight: bold;
      align-self: flex-end;
    }
  }
}

// ############# SELECTOR STYLES FOR ELM ELEMENTS- END #############

// TODO: specific to the review header - find better place and moved
// ############# REVIEW SPECIFIC STYLES - START #############

.review-summary-wrapper {
  display: flex;

  // icon in currency dropdown in review summary bar
  svg {
    height: 14px;
    width: 14px;
  }

  table {
    table-layout: auto;
    border-collapse: collapse;
    border-spacing: 0;
    white-space: nowrap;

    th,
    td {
      padding-left: 15px;
      padding-right: 2px;
    }

    thead tr {
      line-height: 14px;
    }

    thead th {
      font-size: 12px;
      color: rgb(79 84 88 / 100%);
      font-weight: normal;
      margin-right: 12px;
      text-align: center;
    }

    tbody {
      tr {
        font-size: 12px;
        font-weight: bold;
        text-align: center;
      }

      .elm-datepicker--container {
        bottom: 0;
      }

      .elm-datepicker--picker {
        position: absolute;
        left: 1px;
      }
    }
  }
}

.border-right {
  border-right: 1px solid #e7ecee;
  padding-right: 5px;
}

.summary {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

th,
td {
  padding-right: 2px;
}

.summary-divider {
  background-color: #bbb;
  height: 65%;
  margin-left: 6px;
  margin-right: 6px;
  margin-top: 3px;
  width: 1px;
  min-width: 1px;
}

@media only screen and (width <= 1200px) {
  .summary-divider {
    display: none;
  }
}

.icon-wrapper {
  margin-left: 8px;
  cursor: pointer;
}

.items-content {
  transform: translateY(100%);
  opacity: 0;
  transition:
    transform 0.25s,
    opacity 0.25s;
  display: flex;
  margin-top: 2px;
  margin-left: 12px;
}

.items-container--visible .items-content {
  display: flex;
  transform: translateY(0);
  opacity: 1;
}

// ############# REVIEW SPECIFIC STYLES - END #############
