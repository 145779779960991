.playground-nav {
  &__container {
    display: flex;

    button {
      border: 1px solid lightgray;
      margin: 2px;
      padding: 5px;
    }
  }

  &__elem {
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    font-size: 17px;
    font-family: Overpass, 'Open Sans', sans-serif;
    margin: 2px 0 10px 2px;
    background-color: #ddd;

    &-selected {
      background-color: #aad;
    }
  }
}

.playground-content__container {
  margin: 10px;
  flex-grow: 1;
  display: flex;
  height: 100%;
}

.playground-components {
  display: flex;

  &-rows {
    display: flex;
    flex-direction: column;
  }

  &-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  &__elem {
    border: 1px #eee solid;
    padding: 5px;

    h2 {
      text-align: center;
    }
  }

  &-wrapper {
    border: 3px #f88 dashed;
    width: max-content;
    height: max-content;

    // border-bottom: 0;
    // &:last-child {
    //   border-bottom: 3px #faa dashed;
    // }
  }
}

.playground-aggrid-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.playground-components-group {
  display: block;
}

.playground-large-container {
  width: 200px;
  height: 70px;
}
