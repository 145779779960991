// Inspired by: https://gist.github.com/thomasthesecond/00c1422e1e90895ed437
// This is for clamping ag-grid header text
@mixin clamp-ag-header($lines) {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1lh * $lines + 0.1lh);
  white-space: normal !important;
}
