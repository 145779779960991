.trackChanges {
  &--output-and-comparison-dates {
    display: flex;

    & > div {
      width: 50%;
      font-size: 14px;
    }

    .trackChanges--output-datepicker {
      margin-left: 12px;
    }
  }

  &--form-validation-error {
    color: $proda-red-120;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    width: 260px; // Not setting this explicitly leads to some really weird glitching with the scrollbar for some reason.
  }
}

.ag-popup-editor {
  .trackChanges--tagsEditor-container {
    height: 0;

    & > div {
      height: 0;
    }
  }
}

.tc-readonly-tags-container {
  height: 22px;
}

.tc-readonly-tag {
  display: inline-flex;
  background-color: $primary-color;
  color: $text-color-light;
  border-radius: 2px;
  padding-left: 5px;
  padding-right: 5px;
  vertical-align: middle;
  line-height: 21px;

  &:not(:first-child) {
    margin-left: 5px;
  }
}
