#choose-databases-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  p {
    text-align: center;
  }

  p:first-child {
    margin-bottom: 1em;
  }
}
