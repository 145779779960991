// Colour palette

// $proda-blue: #001ca5; // rgba(0, 28, 165, 1)
// $proda-navy: #7390a6; // rgba(115, 144, 166, 1)
// $proda-dark-grey: #404649; // rgba(64, 70, 73, 1)
// $proda-purple: #9a44bf; // rgba(154, 68, 191, 1)
// $proda-red: #ff5a4f; // rgba(255, 90, 79, 1)
// $proda-turquoise: #00c7a6; // rgba(0, 199, 166, 1)
// $proda-yellow: #ffcd1c; // rgba(255, 205, 28, 1)

// $light: #f8f8f8;
// $dark: #0a0a0a;
// $proda-white: #fff;
// $black: #000;

// Colour Scheme / GF 26 May 2023

// $color-primary: $proda-blue;
// $color-secondary: lighten($proda-blue, 60%); // #eef1ff
// $color-accent: $proda-red;
// $color-success: $proda-turquoise;

// $color-warning: $proda-yellow; // We are currently using Proda blue for warnings on review, which may cause issues
// $color-warning: $proda-blue; // Needs review, see line above
// $color-error: $proda-red; // #ea0f00
// $color-active: $proda-red;
// $color-inactive: lighten($dark, 75%); // #c9c9c9
// $color-modified: $proda-purple;

// GF 6 June 2023
// Temporarily retaining these colours because they don't fall under
// the colour scheme but are distinctive.
// Will decide how to replace them.
// $id-col-light-blue: #bcecff; // File Variables
// $deltaPlusGreen: #00b050;
// $border-color: #d9d9d9;

// Colour palette - Descriptive

// Note: *-10 to *-90 denotes a lighter shade
//       *-110 onwards denotes a darker shade

$proda-neon-blue: #0018ea; // rgba(0, 24, 234, 1)
$proda-neon-blue-10: #d7d9f1; // rgba(215, 217, 241, 1)
$proda-navy-blue: #020734; // rgba(2, 7, 52, 1)
$proda-light-blue: #cadefc; // rgba(202, 222, 252, 1)
$proda-light-blue-l95: #e7f0fe; // rgba(231, 240, 254, 1) - Added 16 July 2024
$proda-light-blue-120: #95bcfa; // rgba(149, 188, 250, 1)
$proda-black: #060a16; // rgba(6, 10, 22, 1)
$proda-black-10: #62637f; // rgba(98, 99, 127, 1) - DARK GREY
$proda-white-110: #f8f8f8; // rgba(248, 248, 248, 1)
$proda-white-130: #d6d6e0; // rgba(214, 214, 224, 1)
$proda-white-150: #bbc; // rgba(187, 187, 204, 1) - LIGHT GREY
$proda-purple: #660bbd; // rgba(102, 11, 189, 1)
$proda-purple-10: #d5a8ff; // rgba(213, 168, 255, 1) - Added 23 Apr 2024
$proda-lavender: #7885ff; // rgba(120, 133, 255, 1)
$proda-lavender-10: #e3e5ff; // rgba(227, 229, 255, 1) - Added 23 Apr 2024
$proda-red-20: #fbd7e0; // rgba(251, 215, 224, 1)
$proda-red: #ff002f; // rgba(255, 0, 47, 1)
$proda-red-120: #d00127; // rgba(208, 1, 39, 1)
$proda-teal: #009c93; // rgba(0, 156, 147, 1)
$proda-teal-10: #86e2dd; // rgba(134, 226, 221, 1) - Added 23 Apr 2024
$proda-yellow-20: #fffcdd; // rgba(255, 252, 221, 1)
$proda-yellow: #ffc700; // rgba(255, 199, 0, 1)
$proda-yellow-120: #cfa101; // rgba(207, 161, 1, 1)
$proda-green-20: #bceebd; // rgba(188, 238, 189, 1)
$proda-green: #04cd00; // rgba(4, 205, 0, 1)
$proda-green-120: #0b8400; // rgba(11, 132, 0, 1) - Updated 24 May 2024
$proda-orange: #d83800; // rgba(216, 56, 0, 1) - Updated 24 May 2024
$proda-dusty-pink: #f2d3d3; // rgba(242, 211, 211, 1)
$proda-dusty-pink-l95: #f9ebeb; // rgba(249, 235, 235, 1) - Added 16 July 2024
$proda-white: #fdfdff; // rgba(253, 253, 255, 1)
$proda-celtic-blue: #0071d8; // rgba(0, 113, 216, 1) - Added 24 May 2024

// Colour Scheme - Semantic

$primary-color: $proda-navy-blue;
$secondary-color: $proda-light-blue;
$tertiary-color: $proda-orange; // Updated 24 Apr 2024
$accent-color: $proda-neon-blue;
$danger-bg: $proda-red-20; // Updated 23 Apr 2024
$danger-border: $proda-red-120; // Updated 03 June 2024
$warning-bg: $proda-yellow-20; // Updated 23 Apr 2024
$success-bg: $proda-green-20; // Updated 23 Apr 2024
$disabled-bg: $proda-white-130; // Added 23 Apr 2024
$light: $proda-white-110;
$dark: $proda-black-10;
$text-color-dark: $proda-black;
$text-color-disabled: $proda-black-10; // Added 23 Apr 2024
$text-color-light: $proda-white;

$user-edit: $proda-purple; // Added 23 Apr 2024
$proda-default: $primary-color; // Added 23 Apr 2024
$proda-ignored: $proda-neon-blue-10; // Updated 24 May 2024
$source-data: $proda-lavender; //Added 24 May 2024

$assigned: $proda-purple; // Added 24 Apr 2024
$inferred: $proda-teal; // Added 24 Apr 2024
$inferred-bg: $proda-teal-10; // Added 25 Apr 2024
$prev-assigned: $proda-neon-blue; // Added 24 Apr 2024
$unassigned: $proda-red-120; // Added 24 Apr 2024

$hover-bg: $proda-dusty-pink;
$select: $proda-light-blue;

// Table

$row-header-bg: $primary-color; // Added 23 Apr 2024
$row-header-text: $text-color-light; // Added 23 Apr 2024
$row-pinned-bg: $proda-white-130; // Added 23 Apr 2024
$row-selected-bg: $select; // Added 23 Apr 2024
$row-hover-bg: $hover-bg; // Added 23 Apr 2024
$row-border: $proda-white-150; // Added 23 Apr 2024

$cell-header-bg: $primary-color; // Added 23 Apr 2024
$cell-header-text: $text-color-light; // Added 23 Apr 2024
$cell-selected-bg: $select; // Added 23 Apr 2024
$cell-hover-bg: $hover-bg; // Added 23 Apr 2024
$cell-ignored-bg: $disabled-bg; // Added 23 Apr 2024

$cell-header-ignored-proda-bg: $proda-ignored; // Added 23 Apr 2024
$cell-header-ignored-user-bg: $proda-purple-10; // Added 23 Apr 2024

$cell-header-inferred-bg: $inferred; // Added 23 Apr 2024
$cell-header-edit-user-bg: $user-edit; // Added 23 Apr 2024
$cell-header-edit-user-text: $text-color-light; // Added 23 Apr 2024

$cell-border: $proda-white-150; // Added 23 Apr 2024
$cell-border-selected: $proda-celtic-blue; // Updated 04 June 2024

$shadow: 1px 2px 6px 0 rgb(6 10 22 / 25%);
