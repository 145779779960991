// Customize the look and feel of the grid with Sass variables
// Up-to-date list of variables is available here: http://www.ag-grid.com/javascript-grid-styling/#customizing-sass-variables

// Import default variables
@use '~ag-grid-enterprise/styles' as ag;

/// Makes text break even words when it doesn't fit. Useful for ag-grid header labels.
@mixin ag-header-word-wrap {
  // These custom properties have actually been supported by Edge, Firefox, Safari and Chrome for quite a while now.
  // See https://caniuse.com/#search=line-clamp
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: pre-line;
  overflow-wrap: break-word;
}

// ag-grid doesn't have a convenient way to center header cell
@mixin centerHeaderCell {
  .center-aligned-header .ag-header-cell-label {
    justify-content: center;
    text-align: center;
  }
}

.ag-cell.center-aligned-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* These values are needed to allow Noto Sans interop with Roboto */
.ag-root {
  line-height: unquote('round(1.22em, 1px)') !important;
  letter-spacing: -0.1ex !important;
  font-variation-settings: 'wdth' 95 !important;
}

// fix maximized charts being below the "navbar"
.ag-popup .ag-popup-child {
  z-index: 101;
}

:root {
  // Override Sass variable for output screens
  --ag-grid-size: 4px;
  --proda-celtic-blue: #{$proda-celtic-blue};
  --proda-green-120: #{$proda-green-120};
  --proda-red-120: #{$proda-red-120};
  --proda-white: #{$proda-white};
}

.ag-root-wrapper {
  @include centerHeaderCell;
}

@include ag.grid-styles(
  (
    theme: balham,
  )
);
@include ag.grid-styles(
  (
    themes: (
      balham-output: (
        extend-theme: balham,
        --ag-header-height: 64px,
        --ag-header-foreground-color: $proda-white,
        --ag-header-cell-hover-background-color: $primary-color,
        --ag-header-column-separator-color: $cell-border,
        --ag-row-hover-color: $cell-hover-bg,
        --ag-odd-row-background-color: $proda-white,
        --ag-row-group-indent-size: 8px,
        --ag-range-selection-background-color: $row-selected-bg,
        --ag-selected-row-background-color: $cell-selected-bg,
        --ag-range-selection-border-color: $cell-border-selected,
        --ag-list-item-height: calc(var(--ag-grid-size) * 6),
        --ag-font-family: (
          'Noto Sans',
          'Arimo',
          'Arial',
          'Helvetica',
          '-apple-system',
          'sans-serif',
        ),
        --ag-font-size: 11px,
        --ag-icon-size: 12px,
        // this seems to override more than we want, and we apply our own border
        // anyway (?)
        // cell-horizontal-border: 1px dotted silver,
        --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2),
      ),
      // Override Sass variable for upload screens
      // for table-bounds-table element
      bounds-table: (
          extend-theme: balham,
          --ag-header-background-color: #f5f7f7,
          --ag-header-cell-hover-background-color: #f5f7f7,
          --ag-header-height: 25px,
          --ag-header-foreground-color: $text-color-dark,
        ),
    ),
  )
);

.ag-theme-balham-output,
.review-table {
  --ag-row-height: 22px;
  --ag-cell-padding: 5px;

  .ag-cell {
    line-height: calc(var(--ag-row-height) - 2px);
  }

  .red-overlay {
    background-color: rgba(251, 215, 224, 1) !important;
  }

  .red-overlay::after {
    content: '\f7d9';
    font-family: 'Font Awesome 6 Free', 'Font Awesome 6 Pro',
      'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: gray;
  }

  .purple-overlay.red-overlay::after {
    content: '';
  }

  .purple-overlay {
    background-color: rgba(213, 168, 255, 1) !important;
  }

  .ag-root-wrapper {
    border: none;
  }

  // Charts header bar
  .ag-panel-title-bar {
    height: 42px;

    .ag-panel-title-bar-title {
      font-size: 14px;
      font-weight: bold;
      color: black;
    }

    .ag-panel-title-bar-button-icon {
      font-size: 22px;
      color: black;
    }
  }

  // need NAND of range-right and selected, needs two rules unfortunately
  .ag-cell.ag-cell.ag-cell:not(
      .ag-cell-range-right,
      .ag-cell-range-single-cell,
      .ag-cell-last-left-pinned,
      .ag-cell-focus
    ),
  .ag-cell.ag-cell.ag-cell.ag-cell-range-right:not(
      .ag-cell-range-selected,
      .ag-cell-focus
    ) {
    border-right: 1px dotted $cell-border;
  }

  .ag-floating-top {
    // total row on output and review grids
    overflow: auto !important;
  }

  .ag-header-icon {
    color: inherit;

    .ag-icon {
      color: inherit;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .ag-header {
    .ag-sort-descending-icon,
    .ag-sort-ascending-icon {
      // height + line-height gives the best behaviour across chromium+firefox
      // on my machine, although it might depend on fonts as well.
      height: 15px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .ag-header-icon {
        margin-top: var(--ag-cell-padding);
      }

      .ag-icon {
        margin-left: -3px;
        margin-right: -4px;
      }
    }
  }

  .ag-tool-panel-wrapper {
    // By default ag-grid sets this to 200px, but that's not wide enough,
    // so unset it here so it uses as much space as it needs instead.
    width: unset;
  }
}

.review-table .ag-root {
  .ag-header-row:nth-child(2) {
    border-top: none;
  }

  .ag-row {
    .ag-cell.ag-cell-range-selected {
      border-style: solid;
    }

    border: none;
    border-top: none;
    border-bottom-style: none;
    border-top-style: none;
    border-bottom: 1px solid #cbd7e0;
  }
}

#exchange-rates .ag-cell-edit-input {
  font-size: 11px;
}

// Customize the look and feel of the grid by overwriting ag-grid class styles

// Custom header styling
.ag-theme-balham-output {
  --ag-cell-height: 63px;
  --ag-cell-padding: 5px;

  .ag-header-cell,
  .ag-header-cell:hover {
    padding: 0;
    background-color: $primary-color;
    height: var(--ag-cell-height) !important;

    .ag-header-cell-label {
      height: calc(
        var(--ag-cell-height) - var(--ag-cell-padding) - 1px
      ) !important;
      align-items: flex-start;
    }
    .ag-cell-label-container {
      align-items: flex-start;
    }

    &.ag-header-renamed .ag-header-cell-label .ag-header-cell-text {
      text-decoration: underline;
      text-decoration-style: solid;
      text-decoration-color: $proda-purple;
      text-decoration-thickness: 2px;
    }

    // text column
    .ag-header-cell-text {
      // vertical padding is now applied by ag-grid container
      // padding-top: var(--ag-cell-padding);
      padding-left: 8px;

      @include ag-header-word-wrap;
    }

    // icon column
    span:nth-child(2) {
      width: 16px;
    }
  }

  .ag-row.ag-row.ag-row {
    border: none;
    border-top: none;
    border-bottom-style: none;
    border-top-style: none;
    border-bottom: 1px solid $cell-border;
  }
}

// Custom styling for column menu
.ag-theme-balham-output,
.ag-layout-normal {
  .ag-filter {
    --filter-item-height: 21px;

    // This should match the position-fixing code in BaseGrid.js
    // width: 260px;

    // Each item in the set filter is absolutely positioned by ag-grid through JS.
    // Because our server-side set-filters don't have the "ref" attributes by which it seems
    // ag-grid acts, we need to "fix" things to work there too.
    // In fact, some attributes have !important so that when ag-grid does set style, what we define
    // here still has precedence.
    input.ag-text-field-input,
    select {
      border: 1px solid $proda-white-130;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 12px;
      height: 24px;
      margin-bottom: 5px;
      padding: 4px 8px;

      &:focus {
        outline: none;
        border-color: $primary-color;
        box-shadow: none;
      }
    }

    input[type='date'] {
      margin-bottom: 0;
    }

    .ag-filter-apply-panel {
      button {
        background: $proda-white;
        border: 1px solid $primary-color;
        color: $text-color-dark;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 4px 8px;
        width: 100%;
        font-size: 10px;
        line-height: 14px;

        &:hover {
          background: $hover-bg;
          border-color: $hover-bg;
        }

        &:active {
          background: $secondary-color;
          border-color: $secondary-color;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    label.ag-set-filter-item {
      max-height: var(--filter-item-height);
      cursor: pointer;
    }

    .ag-filter-header-container {
      padding-bottom: 6px;
      border-bottom: solid 1px;
      border-bottom-color: $cell-border;
      border-bottom-color: var(
        --ag-secondary-border-color,
        var(--ag-border-color, $cell-border)
      );
    }

    .ag-set-filter-item-checkbox {
      // seems that new ag-grid set this and it breaks chceckbox
      overflow: unset;
      flex: 0 0 12px;
    }

    .ag-set-filter-item-value {
      // seems that new ag-grid (v29) doesn't set those out of the box
      // _filter-tool-panel.scss
      margin-left: 6px;

      // _common-structural.scss
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1 1 auto;
    }

    .ag-set-filter-list {
      overflow-y: auto !important;
      height: calc(var(--filter-item-height) * 12);

      & > .ag-virtual-list-viewport {
        position: static;
        height: auto;

        & > .ag-virtual-list-container {
          position: static;
          overflow: hidden;

          & > hr.ag-unavailable-separator {
            height: 1px;
            background-color: $proda-white-150;
          }

          .ag-virtual-list-item {
            position: static;
            max-height: var(--filter-item-height);

            .ag-set-filter-item-unavailable {
              color: $proda-white-150;
            }
          }
        }
      }
    }
  }

  .ag-menu {
    font-family:
      'DM Sans',
      -apple-system,
      'Helvetica Neue',
      Helvetica,
      Arial,
      sans-serif;
    font-size: 13px;
    font-weight: 500;

    .ag-menu-option:hover {
      background-color: $hover-bg;
    }

    // TODO: this is dead. Ag-grid uses ag-tabs-header instead.
    // .ag-tab-header {
    //   background-color: #cbd7e0;
    //   border: none;
    //   height: 28px;
    //   margin-bottom: 6px;

    //   .ag-tab {
    //     margin: 0;
    //     border: 0;
    //     height: initial;
    //   }
    // }

    .custom-menu-separator {
      pointer-events: none;

      .ag-menu-option-text {
        display: inline-block;
        color: $dark;
        font-size: 11px;
        padding-bottom: 4px;
        padding-top: 8px;
        margin-left: -20px;
      }
    }
  }
}

// Custom styling for Units List rows
.ag-theme-balham-output {
  .aggregate-row {
    .ag-cell {
      cursor: pointer;
      // background-color: $proda-white;
    }
  }
}

.ag-theme-balham-output .total-row,
.review-table .ag-root .ag-row.ag-row-pinned,
.AdminPanel__AgGrid .ag-root .ag-row.ag-row-pinned {
  .ag-cell {
    background: $row-pinned-bg;
    border-right-width: 1px;
    cursor: pointer;
    font-weight: bold;
  }
}

.ag-theme-balham-output .total-row,
.review-table .ag-root .ag-row.ag-row-pinned {
  .ag-cell:not(.ag-cell-focus) {
    border-right-color: $row-border !important;
  }
}

// Custom styling for clickable cells
.ag-theme-balham-output {
  .row-clickable {
    cursor: pointer;
  }

  .cell-clickable {
    cursor: pointer;
    // TODO: this make it unclear that the cell text is clickable
    color: $primary-color;
  }

  .cell-non-clickable {
    color: $dark;

    .uikit-icon {
      color: inherit !important;
    }
  }

  .cell-clickable-bold {
    @extend .cell-clickable;

    font-weight: bold;
  }

  .editable-link-container {
    display: flex;
    width: 100%;
    height: 19px;
    justify-content: space-between;

    .clickable-icon {
      display: none;
    }

    &:hover {
      .editable-link {
        /** Editable links need to leave some space
        in their container for the pencil icon */
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .clickable-icon {
        display: flex; // override "none"
        color: $primary-color;
        cursor: pointer;
        padding: 2px;
        border: 1px solid transparent;
        line-height: 14px;
      }
    }
  }
}

// Custom styling for blank columns
.ag-theme-balham-output {
  .blank-header,
  .blank-header:hover,
  .blank-cell {
    background-color: $proda-white !important;

    .ag-icon-menu::before {
      color: $text-color-dark;
    }
  }
}

// Custom styling for overlays
.ag-theme-balham-output {
  .ag-overlay-empty {
    padding: 20px;
  }

  .ag-overlay-loading-center {
    padding: 20px;
    border: none;
  }

  .ag-overlay-no-rows {
    position: absolute;
    bottom: 25px;
    pointer-events: auto;
    background-color: $proda-black;
    color: $text-color-light;
    box-shadow: $shadow;
    height: 50px;
    line-height: 53px;
    width: 96%;
    text-align: left;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 10px;

    button {
      appearance: button;
      writing-mode: horizontal-tb !important;
      text-rendering: auto;
      color: buttontext;
      background-color: buttonface;
      box-sizing: border-box;
      margin: 0;
      font: 400 13.3333px Arial;
      padding: 1px 6px;
      border-width: 2px;
      border-style: outset;
      border-color: buttonface;
      border-image: initial;
      float: right;
      width: auto;
      vertical-align: middle;
      font-weight: bold;
      height: 36px;
      margin-top: 7px;
      line-height: 9px;
    }
  }
}

// Custom styling for editable & uneditable columns
.ag-theme-balham-output {
  .ag-row-hover {
    /* editable */
    .editable-cell.ag-cell-not-inline-editing:not(
        .valueChanged,
        .ag-cell-popup-editing
      )::before {
      content: 'Double-click to edit';
      color: $dark;
    }
  }

  /* uneditable */
  .ag-cell-not-inline-editing:not(.ag-cell-popup-editing).uneditable-cell {
    background: $cell-ignored-bg;

    &:hover {
      cursor: not-allowed;
    }

    .Table__Cell--source::after {
      opacity: 0.5;
    }

    &--no-entry-icon::after {
      content: '🚫';
      display: block;
      opacity: 0.2;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
    }
  }
}

// Ensure that the arrows in the grid / the group column don't change size
// when the text of that column overflows.
.ag-theme-balham-output {
  img {
    max-width: initial;
  }
}

// Custom styling for invisible column group row
.Upload__Review__Content {
  .ag-theme-balham-output {
    .ag-sticky-label .ag-header-group-text {
      color: transparent;
      user-select: none;
    }
  }
}

// Custom styling for 'Select Variables' sidebar
.ag-theme-balham-output {
  .ag-column-select-header.ag-focus-managed {
    height: unset;
    padding: 12px 6px;
  }

  .ag-tool-panel-wrapper {
    width: 250px;
  }
}

.ag-checkbox-input-wrapper {
  input {
    cursor: pointer;
  }
}

.ag-theme-balham-output.ag-dnd-ghost {
  background-color: #a0a8ff;
  border-color: #e6e6e6;
}

.ag-theme-balham-output.ag-popup {
  ol,
  ul {
    padding-left: 20px;
  }
}

/** Shading group rows

When making changes to these colours, remember to edit Exports.hs and
excelStyles.js as well */
.ag-theme-balham-output {
  .ag-row-group {
    $row-levels: (
      0: $proda-white,
      1: $light,
      2: $proda-white-130,
      3: $proda-white,
    );

    @each $level, $color in $row-levels {
      &.ag-row-level-#{$level} .ag-cell {
        background-color: $color;
      }
    }

    // override the bg color of grouped rows when focused
    &.ag-row-focus .ag-cell {
      background-color: $row-selected-bg;
    }

    // override the bg color of grouped rows when hovered
    &.ag-row-hover .ag-cell {
      background-color: $row-hover-bg;
    }
  }
}

/** Cell editors */
.ag-cell-inline-editing {
  .singleLine--textEditor-container {
    background-color: $proda-white;
    width: 100%;
    height: 100%;
  }

  /** By default make inputs take the size of their cell */
  input {
    width: 100%;
    height: 100%;
    border: 0;

    &:focus {
      outline: none;
    }
  }
}

.ag-popup-editor {
  border: none !important;

  .multiLine--textEditor-container {
    $pd: 2px;
    $bd: 1px;

    background-color: $proda-white;
    width: min-content;
    height: min-content;

    textarea {
      padding: $pd;
      border-width: $bd;
      min-height: 70px;
      min-width: 270px;
    }

    div.fake-placeholder {
      padding: $pd;
      border: $bd transparent;
      color: $text-color-dark;
    }
  }
}
