@import '../../../../styles/base/colours.scss';

.client-side-grid-property-list {
  --ag-cell-height: 100% !important;

  .ag-popup {
    z-index: 1000;
  }

  .ag-root-wrapper {
    border: $proda-white;
    height: 100%;
  }

  .ag-header {
    background-color: transparent !important;
    border-bottom: transparent !important;
  }

  .ag-floating-top {
    border-bottom: transparent !important;
  }

  .bold-text {
    font-weight: bold;
    color: $text-color-dark;
    border-left: 1px solid $cell-border;
  }

  :not(.ag-cell-range-single-cell).cell-style,
  .empty-cell-style {
    border-left: 1px solid $cell-border;
  }

  .cell-style:focus,
  .cell-style:active {
    border: 1px solid #bbc;
  }

  .cell-is-editable {
    .cell-is-editable-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }

    .cell-is-editable-text {
      flex: 1 0 0%;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .cell-is-editable-icon {
      display: none;
      flex: 0 0 auto;
      padding: 0 0 0 4px;
      align-items: center;
      cursor: pointer;
    }

    &:hover {
      .cell-is-editable-icon {
        display: flex;
      }
    }
  }

  .ag-cell-range-selected {
    border: 1px solid #bbc;
  }

  .ag-row-pinned,
  .ag-row-pinned:not(.ag-full-width-row)::before {
    background-color: $row-pinned-bg !important;
  }

  .ag-row-hover:not(.ag-full-width-row)::before {
    background-color: $cell-hover-bg !important;
  }

  .ag-row-selected::before {
    background-color: $cell-selected-bg !important;
  }

  .header {
    font-weight: bold;
    color: $text-color-dark;
    font-size: 12px;
    background-color: $proda-white !important;

    &--transparent {
      background-color: $proda-white !important;

      :hover {
        background-color: $proda-white !important;
      }
    }

    &--source-data {
      color: $text-color-dark;
      background-color: $proda-lavender !important;
      border-left: 1px solid $cell-border;

      :hover {
        color: $text-color-dark;
        background-color: $proda-lavender;
      }
    }

    &--empty {
      border-left: 1px solid $cell-border;
      background-color: $cell-ignored-bg !important;

      :hover {
        background-color: $cell-ignored-bg !important;
      }
    }

    &--proda {
      border-left: 1px solid $cell-border;
      color: $text-color-light;
      background-color: $row-header-bg !important;

      :hover {
        color: $text-color-light;
        background-color: $row-header-bg !important;
      }
    }

    &--status {
      padding-left: 19px !important;
      border-left: 1px solid $cell-border !important;
    }

    &--portofolio {
      border-right: 1px solid $cell-border !important;
    }
  }

  // In combination, these rules give a 1px solid outline to a selected row
  .ag-row.ag-row:has(+ .selected-group-row) {
    border-bottom-color: $cell-border-selected;
  }

  .ag-row.ag-row.selected-group-row {
    border: solid $cell-border-selected;
    border-width: 0 1px 1px;
    overflow: hidden;

    > * {
      transform: translateX(-1px);
    }

    .ag-cell-range-selected {
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right-style: dotted;
    }

    &:first-child {
      border-top: 1px solid $cell-border-selected;
    }
  }
}
