%resetInput {
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid $primary-color;
  height: 2.5em;
  padding: 0 1em;
}

%resetFieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.input {
  @extend %resetInput;

  padding: 0 1em;
  background: transparent;

  &-disabled {
    background-color: $disabled-bg;
    color: $text-color-disabled;
  }

  &-fullwidth {
    width: 100%;
  }

  &-large {
    width: 80%;
  }

  &-toggle {
    list-style: none;

    &-label {
      font-size: 0.8rem;
      text-transform: uppercase;
      display: block;
      color: $text-color-dark;
      font-weight: 400;
    }

    &-value {
      font-size: 1rem;
      color: $text-color-dark;
      font-weight: bold;
    }

    input {
      border-radius: 0;
      line-height: 2em;
      padding: 0 !important;
    }

    button {
      float: right;
    }
  }

  &-rentrollDate {
    margin-left: 4px;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;
  }
}

.formgroup-proj-port {
  margin-bottom: 0.5em;

  label {
    font-size: 0.8rem;
    font-weight: normal;
  }
}

.formgroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;

  h3,
  h4 {
    margin: 0;
  }

  h3 {
    line-height: 1.5em;
    padding: 0;
    font-size: 0.9rem;
    font-weight: bold;
    color: $text-color-dark;
  }

  h4 {
    font-size: 0.8rem;
    font-weight: normal;
    color: $text-color-dark;
  }

  &--combined {
    input[type='checkbox'] {
      height: 1em;
    }

    input[type='checkbox']:checked {
      color: red;
    }
  }

  &-option {
    padding: 0 0 0.6em;
  }

  label {
    font-size: 0.8rem;
    font-weight: normal;
  }

  input {
    @extend %resetInput;

    &[type='radio'] {
      position: absolute;
      outline: none;
      margin: 0 0 0 -15px;
      padding: 0;
      font-size: 0.8rem;
    }
  }

  fieldset {
    border: none;
    margin: 0 0 0 15px;
    padding: 0;

    label {
      position: relative;
      margin-right: 25px;
      vertical-align: middle;
    }

    span {
      margin-left: 5px;
      margin-right: 10px;
    }
  }
}

.fieldset_proj-port {
  display: flex;

  input {
    height: auto !important;
    margin-left: 5px;
  }
}
