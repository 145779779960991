// Note: this class is also being used for Date Region Select in the setting page.
.Review__Currency,
.Review__Date_Region {
  display: flex;
  align-items: center;

  &__Label {
    font-size: 12px;
    color: #4f5458;
    font-weight: normal;
    margin-right: 9px;
    width: 90px;
    text-align: right;
    display: flex;
    align-items: center;
  }

  &__Dropdown {
    width: 110px;

    &__SingleValue {
      display: flex;
      align-items: center;
      position: absolute;

      &__Flag {
        min-width: 24px;
        margin-right: 3px;
        margin-left: 3px;
      }

      & div:last-child {
        position: relative;
        padding-top: 3px;
        min-width: 33px;
        transform: unset;
      }
    }

    &__Option {
      display: flex;
      align-items: center;
      color: $text-color-dark;

      &__Flag {
        min-width: 24px;
        margin-right: 3px;
        margin-left: 3px;
      }

      // todo: these styles need refactoring
      // Top level and nested "div:active" selectors are needed to override the default styles.

      div:active {
        background-color: inherit; // inherits white bg which is better then blue bg for the option and white bg for the flag
        color: inherit;
      }

      &--focused {
        background-color: $hover-bg;

        div,
        div:active {
          background-color: inherit;
          color: inherit;
        }
      }

      &--selected {
        background-color: $select;

        div,
        div:active {
          background-color: inherit;
          color: inherit;
        }
      }
    }
  }
}
