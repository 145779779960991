/** Animation Classes **/

@keyframes glow_anim {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.4);
  }
}

.glow_anim {
  transform-origin: 50% 50%;
  animation: 0.3s linear 0s infinite alternate glow_anim;
}

@keyframes shadow-flash {
  0% {
  }

  10% {
    border-top: 0 $accent-color solid;
    border-left: 0 $accent-color solid;
    border-right: 0 $accent-color solid;
    border-bottom: 0 $accent-color solid;
    box-shadow: 0 0 10px 0 $accent-color;
  }

  50% {
    border-top: 0 $accent-color solid;
    border-left: 0 $accent-color solid;
    border-right: 0 $accent-color solid;
    border-bottom: 0 $accent-color solid;
    box-shadow: 0 0 10px 0 $accent-color;
  }

  100% {
  }
}

/* prefixing with body to prioritize this over another local class on the
   div we want to be flashing */
body .shadow-flash {
  border-top: 0 transparent solid;
  border-left: 0 transparent solid;
  border-right: 0 transparent solid;
  border-bottom: 0 transparent solid;
  box-shadow: 0 0 10px 0 transparent;
  animation: shadow-flash 2.5s linear;
}

/** Loading **/

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading {
  opacity: 0.5 !important;
  width: auto;
  height: auto;
  position: relative;
}

.loading::after {
  content: '\0e980';
  font-family: Icomoon;
  line-height: 1.2;
  color: $text-color-dark;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%);
  font-size: 2rem;
  animation: spin 1s linear infinite;
}

@mixin flipHorizontal() {
  transform: rotate(180deg);
}

@mixin flipVertical() {
  transform: rotate(90deg);
}

/* SpinKit Loader: https://tobiasahlin.com/spinkit/ */
.sk-folding-cube {
  margin: 230px auto;
  width: 40px;
  height: 40px;
  position: relative;
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  transform: scale(1.1);
}

.sk-folding-cube .sk-cube::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $primary-color;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  transform: scale(1.1) rotateZ(90deg);
}

.sk-folding-cube .sk-cube3 {
  transform: scale(1.1) rotateZ(180deg);
}

.sk-folding-cube .sk-cube4 {
  transform: scale(1.1) rotateZ(270deg);
}

.sk-folding-cube .sk-cube2::before {
  animation-delay: 0.3s;
}

.sk-folding-cube .sk-cube3::before {
  animation-delay: 0.6s;
}

.sk-folding-cube .sk-cube4::before {
  animation-delay: 0.9s;
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
