.nav {
  &-app {
    &__menu {
      display: inline-flex;
      padding-right: 0;
      margin-left: auto;
    }

    &__nav-button-icon-group {
      @extend .col-auto;

      align-items: center;
      border-left: 1px solid $proda-black;
      display: flex;
      height: 40px;
      pointer-events: auto;
      padding: 0 5px;

      &--right-border {
        border-left: none;
        border-right: 1px solid $proda-black;
      }

      .assistant-button {
        width: 40px;
        height: 40px;

        .assistant-link {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            border-color: $hover-bg;
            background-color: $hover-bg;
          }

          &:active {
            border-color: $select;
            background-color: $select;
          }

          .Icon {
            height: 21px;
            width: 21px;
            padding-top: 0;
            fill: $text-color-dark;
          }
        }
      }

      .button-container {
        background-color: transparent;
        border-radius: 5px;
        display: flex;
        font-size: 20px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        outline: none;
        pointer-events: all;
        text-align: center;
        vertical-align: middle;
        color: $text-color-dark;
        fill: $text-color-dark;

        & > * {
          border-radius: 4px;
          box-sizing: border-box;
          color: $text-color-dark;
          height: 100%;
          width: 100%;
        }

        &:active {
          border-color: $select;
          background-color: $select;
        }

        &:hover,
        *:hover {
          border-color: $hover-bg;
          background-color: $hover-bg;
          cursor: pointer;
        }

        .button-disabled:hover {
          background-color: inherit;
          color: inherit;
          cursor: not-allowed;
        }
      }

      &-info {
        display: flex;
        flex-direction: column;
        pointer-events: all;
        font-weight: normal;
        font-size: 14px;
        text-align: right;

        &__menu {
          position: absolute;
          top: 4em;
          right: 1em;
          box-shadow: 1px 1px 5px $proda-black;
          border-radius: 3px;
          background-color: $proda-white;
          pointer-events: all;
          z-index: 1000;

          ul {
            @extend %resetList;

            li {
              color: $text-color-dark;
              font-size: 0.8rem;

              a {
                color: inherit;
                cursor: pointer;
                display: flex;
                width: 100%;
                height: 100%;
                padding: 8px 20px;

                &:hover {
                  background-color: $hover-bg;
                }
              }

              &.nav-app__nav-button-icon-group-info__menu-username {
                padding: 8px 20px;
                background-color: $disabled-bg;
                color: $text-color-dark;
                cursor: default;
                font-style: italic;
              }
            }
          }
        }
      }
    }
  }

  &-page {
    @extend .nav-header;

    position: sticky;
    border-bottom: 1px solid darken($light, 13%); // #d8d8d8
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    z-index: 2;

    h1,
    h2 {
      font-size: 0.9rem;
      align-self: center;
    }

    // TODO: this could be eliminated since we do not use h1 apart form:
    // - file-input which is greyish anyway
    // 404, 500 and user-interactive.html pages which are not wrapped by -page anyway
    // leaving for now just in case
    h1 {
      color: $text-color-dark;
      margin: 0 2em 0 0;
    }

    h2 {
      color: $text-color-dark;
    }

    &--upload {
      &-item {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        justify-content: flex-end;
        align-items: end;
        margin-left: 25px;
        margin-right: 8px;
        overflow: hidden;

        &--label {
          color: $text-color-dark;
          font-weight: 400;
        }

        &--value {
          color: $text-color-dark;
          font-weight: bold;
          overflow: hidden;
          padding-left: 8px;
          padding-right: 20px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .nav-icons {
      @include select-disabled;

      ::before {
        margin: 0 0.5em 0 0;
      }
    }
  }

  &-sidebar {
    &--fixed {
      &-bottom {
        background-color: $proda-white;
        border-top: 1px solid $proda-white-130;
        display: flex;
        height: 64px;
        z-index: 1000;

        .btn {
          width: 45%;
          margin: 1em 0.5em;
          line-height: 1.8em;

          &-primary {
            background-color: $primary-color;
            color: $text-color-light;

            &:disabled {
              border: 1px solid $dark;
              background-color: $disabled-bg;
              color: $text-color-disabled;
              opacity: 1;
              cursor: default;
            }
          }

          &-secondary {
            background-color: $proda-white;
            color: $text-color-dark;
            border: 1px solid $primary-color;
          }
        }
      }
    }
  }

  // review sidebar tabs
  &-steps {
    display: flex;
    height: 46px;

    &-item {
      flex: 1 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 2px solid $proda-white-130;

      svg {
        box-sizing: content-box;
        height: 23px;
        width: 23px;
        color: $accent-color;
      }

      i.Icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--disabled {
        svg {
          color: $disabled-bg;
        }
      }

      &--active {
        border-bottom: 2px solid $tertiary-color;

        svg {
          color: $tertiary-color;
        }
      }
    }

    &-separator {
      flex: 0 0 2px;
      background-color: $proda-white-130;
      height: 60%;
      align-self: center;
      margin: 4px;

      &:last-of-type {
        display: none;
      }
    }

    // icons size
    .table {
      width: 30px;
      height: 30px;
      padding: 14px 0;
    }

    .idcol {
      width: 30px;
      height: 30px;
      padding: 14px 0;
    }

    .idrow {
      width: 30px;
      height: 30px;
      padding: 14px 0;
    }

    .idprop {
      width: 34px;
      height: 30px;
      padding: 14px 0;
    }

    .review {
      width: 34px;
      height: 30px;
      padding: 14px 0;
    }
  }
}
