@import '_helpers';

.idcol-page {
  .row--fluid-left {
    padding-left: 2em;
    align-items: center;
    margin-right: 30px;

    p {
      text-transform: uppercase;
      color: $text-color-dark;
      margin-right: 6px;
      padding: 0;
    }
  }
}

id-col-table > div {
  height: 100%;
  overflow: auto;
  width: 100%;
}

id-col-table {
  .ag-ltr .ag-has-focus .ag-row.ag-row-focus .ag-cell.ag-cell-focus {
    border: none;
  }

  .ag-ltr
    .ag-has-focus
    .ag-row.ag-row-focus
    .ag-cell.ag-cell-focus.highlighted-id-col {
    border-top: none;
    border-bottom: none;
    border-left: 2px solid $cell-border;
    border-right: 2px solid $cell-border;
  }

  .ag-header .ag-header-container .ag-header-row {
    .ag-header-cell {
      background-color: $primary-color;
      border-right: 2px solid transparent;
      border-left: 1px solid $cell-border;
      border-top: 1px solid transparent;
      color: $text-color-light;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      padding-left: 9px;
      padding-right: 0;

      &.highlighted-id-col {
        border-top: 1px solid $cell-border-selected;
      }

      &.user-id-col {
        background-color: $proda-purple;

        &:hover,
        &.ag-header-active {
          background-color: $proda-purple !important;
        }

        &.ignored-id-col {
          background-color: $cell-header-ignored-user-bg !important;
          color: $text-color-dark;
        }
      }

      &.prediction-id-col {
        &.ignored-id-col {
          background-color: $cell-header-ignored-proda-bg;
          color: $text-color-dark;

          &:hover {
            background-color: $cell-header-ignored-proda-bg;
          }
        }

        &:hover {
          background-color: $primary-color;
        }
      }

      &.labelset-id-col {
        background-color: $inferred !important;
        color: $text-color-dark;

        &.ignored-id-col {
          background-color: $inferred-bg !important;
          color: $text-color-dark;
        }
      }
    }

    .ag-header-cell.highlighted-id-col + .ag-header-cell {
      border-left: 1px solid transparent;
    }

    .ag-header-cell-label span {
      align-self: flex-start;

      @include clamp-ag-header(5);
    }

    .ag-header-cell-menu-button {
      display: none;
    }

    .ag-header-cell::after {
      display: none;
    }

    .ag-icon {
      color: $text-color-light;
    }
  }

  .ag-cell {
    border: 1px dotted $proda-white-150;
    border-top: none;
    padding-left: 4px;
    padding-right: 4px;

    &.highlighted-id-col {
      color: $text-color-dark;
    }

    &.table-header-id-col {
      background-color: $secondary-color;
      white-space: initial;
      overflow-wrap: break-word;
    }

    &.ignored-id-col {
      background-color: $disabled-bg;
    }
  }

  .ag-cell.highlighted-id-col,
  .ag-header
    .ag-header-container
    .ag-header-row
    .ag-header-cell.highlighted-id-col {
    &--single {
      border-left: 1px solid $cell-border-selected;
      border-right: 1px solid $cell-border-selected;
    }

    &--middle {
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
    }

    &--left {
      border-left: 1px solid $cell-border-selected;
      border-right: 1px solid transparent;
    }

    &--right {
      border-left: 1px solid transparent;
      border-right: 1px solid $cell-border-selected;
    }
  }

  .ag-row.ag-row.ag-row {
    border: none;
    border-bottom: none;
    border-top: none;
    border-bottom-style: none;
    border-top-style: none;

    // disable the row hover highlight
    &.ag-row-hover::before {
      content: none;
    }
  }

  .ag-ltr div div.ag-row.ag-row-last:not(.ag-row-pinned) {
    div.ag-cell.highlighted-id-col,
    div.ag-cell.ag-cell-focus.highlighted-id-col {
      border-bottom: 2px solid $proda-white-150;
    }
  }

  .idcol-header-row {
    background-color: $secondary-color;

    .ag-cell {
      line-height: inherit !important;
      padding: 5px 9px;
    }

    &.ag-row-hover::before {
      display: none;
    }

    div.ag-cell-wrapper {
      height: auto;

      span.ag-cell-value {
        height: auto;
        overflow: hidden;
        overflow-wrap: anywhere;
        white-space: pre-wrap;
      }
    }
  }

  .idcol-last-header-row {
    border-bottom: 1px solid $cell-border !important;

    div.ag-cell {
      border-bottom: none;
    }
  }
}

.id-col-status-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: flex-end;
  margin-right: 22px;
  width: 100%;
}

.id-col-status {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  margin-left: 18px;
  font-size: 12px;
  gap: 4px; // Use gap for spacing between icon and text

  & i {
    margin-right: 4px;
    vertical-align: middle;
  }

  &-icon {
    display: inline-flex;
    align-items: center;
    height: 12px;
    width: 12px;

    svg {
      height: 12px;
      width: 12px;
    }
  }
}

select.LabelsetSelect {
  min-height: 25px;
  overflow: hidden;
  padding-top: 6px;
  text-overflow: ellipsis;
}
