// style-elements puts styles on .el which we want to override. Doubling the
// selector makes it more specific.

$uploadSidebar: 360px;

.complex-data-icon {
  width: 12px;
  margin-left: 5px;
  cursor: pointer;
}

.trigger-text {
  color: black;
}

.trigger-text:hover {
  text-decoration: underline;
}

.upload-notification.upload-notification {
  padding: 3px;
  margin-bottom: 5px;
  font-size: 12px;
  background-color: #fbe0df;
}

.Upload {
  &__Nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    gap: 4px;

    p {
      @extend %resetTxt;

      line-height: 1.5em;
    }
  }

  &__Grid {
    display: grid;

    // IE/Edge CSS Grid layout works according to old specs.
    // Instead of auto use 1fr
    -ms-grid-columns: $uploadSidebar 1fr;
    grid-template-columns: $uploadSidebar 1fr;
    grid-template-rows: 1fr;
    height: calc(100vh - 60px);
    width: 100%;

    &__Sidebar {
      box-shadow: 1px 1px 6px 0 $proda-black;
      z-index: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100vh - 60px);
      min-width: 360px;

      .sidebar-content-fixed {
        height: 25px;
      }

      .nav-sidebar--fixed-bottom {
        button {
          border-radius: 0;
          font-size: 16px;
          height: 100%;
        }
      }

      &--withpropbar {
        box-shadow: none;
      }

      .sidebar-content-fixed {
        .Button {
          margin-top: 10px;
        }
      }

      .review-content-fixed {
        margin: 10px 0;
      }
    }

    &__Content {
      overflow: hidden;
      width: 100%;

      &__Main {
        overflow: hidden;
        height: calc(100vh - 105px);
        width: 100%;
      }
    }
  }

  &__Review {
    display: grid;
    grid-template-columns: $uploadSidebar 1fr;

    .active-users {
      padding: 7px 12px;
      position: relative;
      display: flex;

      &.others-active {
        color: $tertiary-color;
      }

      .active-users-icon {
        font-size: 18px;
        height: 24px;
      }

      .active-users-count {
        bottom: 0;
        right: 0;
        font-weight: bold;
        font-size: 12px;
        position: absolute;
      }
    }

    .review-summary-currency {
      margin-left: 0;

      th,
      td {
        padding-left: 0;
        padding-right: 0;
      }

      &-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgb(250 250 250 / 50%);
        z-index: 99;
      }
    }

    &__Content {
      &--wrap {
        position: relative;
        overflow: auto;
        background-color: $proda-white;
        height: calc(100vh - 106px);
        width: calc(100vw - 360px);
      }

      &__Overlay {
        background-color: rgb(250 250 250 / 50%);
        height: calc(100vh - 106px);
        left: 0;
        position: absolute;
        top: 0;
        width: calc(100vw - 360px);
        z-index: 99;
      }
    }
  }
}

.Upload__Grid__Sidebar__Content,
.Upload__Grid__Propbar__Content {
  padding: 20px;
  height: calc(100vh - 176px);
  overflow-y: auto;
  box-sizing: border-box;

  h2 {
    color: $accent-color;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
  }

  // this is only property assignment element
  .formgroup > h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    margin-top: 15px;
    text-transform: uppercase;
  }

  h3 {
    color: $accent-color;
  }

  p {
    font-size: 12px;
    line-height: 18px;
    margin: 0;
  }

  hr {
    margin-bottom: 15px;
    border-width: 1px;
    border-style: inset;
  }

  .legend-badge {
    display: inline-block;
    padding: 2px 4px;
    line-height: 14px;
    border-radius: 2px;
    font-size: 10px;
    margin: 0 1px;
    font-weight: bold;
  }
}

.sidebar-container {
  p {
    font-size: 12px;
    margin-top: 0;
  }

  hr {
    border: none;
    margin: 5px;
    height: 1px;
    background-color: $primary-color;
  }

  input,
  select {
    font-size: 12px;
    height: 2em;
  }

  select {
    padding-top: 4px;
  }
}

.BoundsWidget__container {
  font-size: 12px;
  margin-top: 40px;
  text-align: center;
  align-items: flex-start;
  position: relative;

  .body.row,
  .header,
  .body {
    position: absolute;
    color: $text-color-dark;
  }

  .body.row {
    left: 170px;
    top: -15px;
  }

  .header {
    top: 20px;
    left: 5px;
  }

  .body {
    top: 90px;
    left: 5px;
  }
}

.BoundsWidget {
  height: 150px;
  width: 100%;
  position: relative;

  $header-height: 55;

  font-size: 16px;
  font-weight: bold;
  color: $text-color-dark;
  text-align: center;
  text-transform: uppercase;
  align-self: flex-end;

  .BoundsWidget__header {
    background-color: $secondary-color;
    display: flex;
    flex-direction: column;
    height: #{$header-height}px;
    justify-content: center;
    text-align: center;
  }

  .BoundsWidget__body {
    background-color: $proda-white-130;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 95px;
  }

  .BoundsWidget__selector {
    background-color: $danger-bg;
    border-radius: 50%;
    cursor: pointer;
    height: 18px;
    position: absolute;
    width: 18px;
    border-color: $primary-color;
    border-style: solid;

    &--success {
      background-color: $primary-color;
    }

    &--hs {
      top: -8px;
    }

    &--he {
      top: $header-height - 10px;
    }

    &--re {
      bottom: -8px;
    }

    &--left {
      left: -8px;
    }

    &--right {
      right: -8px;
    }
  }
}

.RowMatch {
  &__UnitCounter {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: $light;
    border-radius: 5px;
    padding: 1em 0;
    margin-top: 25px;

    &__Item {
      border-right: 1px solid #bcbcbc;
      text-align: center;

      &:last-of-type {
        border: none;
      }

      &:first-of-type {
        font-weight: bold;
      }

      &__Label {
        line-height: 1em;
        font-size: 1rem;
      }
    }
  }
}

.ValidationCard {
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);

  &--collapse {
    border: none;
  }

  &--error {
    border-left: 7px solid $proda-red;
  }

  &--warning {
    border-left: 7px solid $proda-yellow;
  }

  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  margin: 10px 0;
  padding: 7px 10px;
  width: 100%;

  &__Header {
    font-size: 12px;
    font-weight: 700;
    display: flex;
    text-align: left;

    &__Count {
      color: $text-color-dark;
      background-color: rgb(0 0 0 / 0%);
      box-sizing: border-box;
      width: inherit;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      text-decoration: none;
      text-align: left;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      transition:
        background-color 400ms,
        border-color 300ms,
        color 100ms;
    }

    &__Title {
      font-size: inherit;
      margin-left: 5px;
    }
  }

  &__Body {
    transition:
      max-height 200ms 0ms ease-in-out,
      padding-top 200ms 0ms ease-in-out;

    &__Description {
      font-size: 12px;
      max-height: 140px;
      overflow: auto;
      margin-top: 5px;

      .inner-html {
        font-size: inherit;

        p {
          font-size: inherit;
        }
      }
    }

    &__ButtonRow {
      display: flex;
      justify-content: space-between;
      margin: 5px 0;

      &__Dismiss {
        background-color: rgb(0 0 0 / 0%);
        border: 0 solid;
        border-color: $danger-bg;
        border-radius: 5px;
        box-sizing: border-box;
        color: $proda-red;
        width: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-decoration: none;
        text-align: left;
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-direction: row;
        transition:
          background-color 400ms,
          border-color 300ms,
          color 100ms;

        i {
          fill: currentcolor !important;
          outline: none;
          margin-right: 3px;
          font-size: 0.8em;
        }
      }

      &__Next {
        background-color: rgb(0 0 0 / 0%);
        border: 0 solid;
        border-color: $primary-color;
        border-radius: 5px;
        box-sizing: border-box;
        color: $text-color-dark;
        width: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-decoration: none;
        text-align: left;
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;
        transition:
          background-color 400ms,
          border-color 300ms,
          color 100ms;

        i {
          fill: currentcolor !important;
          float: right;
          font-size: 0.8em;
          outline: none;
          margin-left: 3px;
          font-weight: bold;
        }
      }
    }
  }
}
