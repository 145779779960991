.toolbar {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  &--2-col {
    grid-template-columns: 1fr auto;
  }

  .nav-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    // Reserves space for absolutely positioned action buttons (e.g. in Tenant/Unit Management pages)
    &--reserved-slot {
      width: 444px;
    }
  }

  .nav-tabs {
    .separator {
      display: inline-block;
      width: 1px;
      height: 20px;
      background-color: $proda-white-130;
      margin: 0 8px;
    }

    .nav-tabs:not(.nav-tabs--non-collapsible) {
      @media screen and (max-width: 1600px) {
        display: none;
      }
    }

    .nav-tabs-menu-wrapper {
      display: none;

      @media screen and (max-width: 1600px) {
        display: flex;
      }

      .nav-tabs-menu {
        .uikit-dropdown__content {
          min-width: 200px;
          left: 0;
        }
      }
    }

    .nav-map-list-tabs {
      display: flex;

      &__tab {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        padding: 8px;
        font-size: 12px;
        line-height: 15px;
        color: $text-color-dark;
        background-color: $proda-white;
        border: 1px solid $proda-white-150;

        &:hover {
          cursor: pointer;
        }

        &:not(:first-child) {
          border-left: none;
        }

        &--active {
          background-color: $select;
        }

        svg {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .nav-tabs {
    display: flex;
    gap: 18px;

    &__tab {
      display: flex;
      align-items: center;
    }

    a:not(.uikit-dropdown__select-link) {
      @extend .compat-heading;

      color: $accent-color;
      font-size: 14px;
      font-weight: bold;
      text-wrap: nowrap;

      display: flex;
      align-items: center;
      gap: 8px;

      svg.Icon {
        height: 12px;
        width: 12px;
        fill: $proda-navy-blue;
      }
    }

    .selected {
      a {
        color: $tertiary-color;
        cursor: default;
      }
    }

    .disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}
