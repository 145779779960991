// This has been moved from fileList.scss file once migrated from elm to ts
.copy-file-modal {
  min-height: 500px;
  display: flex;
  flex-basis: 0;
  overflow: auto;
  box-shadow: 0 0 0.5em rgb(0 0 100 / 50%);

  & > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .copy-file-scroll-area {
    flex-grow: 1;
    overflow: auto;
    padding: 5px;
    padding-top: 1em;
  }

  margin: 10px;

  h2 {
    padding: 0.5em;
    background: $proda-white;
  }

  h4 {
    margin-block-end: 10px;
  }

  details {
    margin-block-end: 10px;
  }

  #target-properties {
    display: none;
  }

  .current-and-target {
    display: flex;
    flex-direction: row;

    & > li,
    & > div {
      margin-right: 10px;
    }

    & > li > span {
      height: 20px;
      align-items: flex-end;
    }

    .list-block--item {
      margin-bottom: 0;
    }

    .current-and-target--operator {
      text-align: center;
      position: relative;
      flex-grow: 2;

      .current-and-target--symbol-container {
        position: absolute;
        inset: 0 0 10%;
        display: flex;
        align-items: center;
        opacity: 0.2;

        .symbol-line {
          border-top: 2px solid;
          width: 100%;
        }

        .symbol-arrow {
          border-top: 3px solid;
          border-right: 3px solid;
          transform: rotate(45deg);
          width: 10px;
          height: 10px;
          margin-left: -10px;
        }
      }
    }

    .spacer {
      flex-grow: 1;
    }
  }

  .stats-row {
    display: flex;

    & > span {
      margin-right: 10px;
      align-items: baseline;
      align-self: flex-end;
    }
  }

  .file-name-list {
    padding: revert;
  }

  .move-copy-fieldset-container {
    height: 22px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .choose-op::before {
    content: '-';
  }

  .in-progress {
    margin-block-end: 10px;
  }

  .operation-info {
    tr th {
      white-space: nowrap;
    }

    transition: opacity 1s;

    copy-files-autocomplete input {
      min-width: 300px;
    }
  }

  .opaque {
    opacity: 0.1;
  }

  .hide {
    display: none;
  }

  .invisible {
    visibility: hidden;
  }

  .Icon {
    height: 16px;
    width: 16px;
    margin-right: 2px;
  }

  fieldset > label {
    margin-right: 5px;

    input {
      margin-right: 3px;
    }
  }

  .success-text {
    font-size: 30px;
    align-self: center;
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
  }

  .process-error-label {
    align-self: center;
    background: #fa0;
    margin: auto;
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .success-row {
    margin-top: auto;
    height: 60px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-content: stretch;

    .button-row {
      flex-grow: 1;
      flex-basis: 0;
    }
  }

  .button-row {
    margin-top: auto;
    height: 60px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-content: stretch;
    box-shadow: 0 0 1.5em rgb(0 0 0 / 30%);

    button,
    input[type='submit'],
    input[type='button'] {
      flex-grow: 1;
      margin: 0;
      border-radius: 0;

      &:not(:first-child) {
        border-left: 0;
      }

      &.dangerous-button:enabled {
        background-color: $danger-bg;
      }
    }
  }

  .SimpleDropdownContainer {
    position: relative;
    // todo: this seems wrong
    border: 1px solid $secondary-color;
    box-sizing: border-box;
    border-radius: 3px;
    width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;

    .SimpleDropdown {
      outline: none;
      font-size: 12px;
      font-weight: normal;
      margin: 0;
      padding-top: 3px;
      cursor: default;

      &.cursor {
        cursor: pointer;
        margin-left: 5px;
        flex-grow: 1;
        font-size: 16px;
        color: black;
        font-weight: bold;
        line-height: 1em;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }

      &-list {
        z-index: 50;
        background: $proda-white;
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        border: 1px solid darken($light, 13%); // #d8d8d8
        border-top: none;
        box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
        max-height: 200px;
        overflow: auto;
      }
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid lightgray;
      padding: 0 3px;
      cursor: pointer;

      &:hover {
        background-color: $primary-color;
        color: $text-color-light;

        svg {
          fill: $proda-white;
        }
      }
    }

    .searchDropdown-arrow {
      padding: 0;
      margin: 0 5px;
    }
  }

  textarea {
    width: 99%;
    height: 500px;
  }

  div.property-project-and-portfolio {
    div.property {
      white-space: nowrap;
    }

    div.project-and-portfolio {
      font-size: 12px;
      display: flex;
      flex-flow: row wrap;

      svg {
        height: 1em;
        width: 1em;
        fill: $primary-color;
      }

      span:first-child {
        padding-right: 10px;
      }

      span {
        white-space: nowrap;
      }
    }
  }

  table,
  th,
  td {
    padding: 5px;
    border: 1px solid black;
    border-collapse: collapse;
  }
}
