.Alert {
  border-radius: 5px;
  font-size: 0.8rem;
  max-height: 120px;
  overflow: auto;
  padding: 1em;

  p {
    margin: 0;
    text-align: left;
  }

  // GF 27 May 2023: Shouldn't this be yellow if a warning or error if red?
  &--warning {
    background-color: $danger-bg;
    border: 1px solid $proda-red;
    margin-bottom: 1em;
  }
}
