.rentroll-timeline-page {
  height: 100%;
  line-height: 1.5;

  .nav-page {
    box-shadow: none;
  }

  .page-content--table {
    overflow: auto;
    flex: 1 1 0;
  }
}

rentroll-timeline {
  .grid {
    display: grid;
    font-size: 0.7rem;
    grid-template-columns:
      70px
      minmax(70px, auto)
      minmax(120px, auto)
      minmax(max-content, 350px)
      minmax(max-content, 1fr);
    grid-auto-columns: min-content;
    grid-auto-rows: min-content;

    .grid-rowfiller {
      background-color: $proda-white;
      border-bottom: 1px solid $row-border;
      border-right: 1px solid $row-border;
      z-index: 0;
    }

    .grid-rowfiller.even-row {
      background-color: #f2f2f2;
    }

    .grid-columnfiller {
      background-color: transparent;
      border-bottom: 1px solid $row-border;
      border-right: 1px solid $row-border;
      min-width: 0;
      z-index: 1;
    }

    .grid-header {
      border-bottom: 1px solid $row-border;
      border-right: 1px solid $row-border;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 0 4px;

      span {
        text-decoration: underline;
        flex-grow: 1;
        text-align: center;
      }

      &.align-right {
        justify-content: flex-end;
      }

      &.align-left {
        justify-content: flex-start;
      }
    }

    [class*='sticky-row-'] {
      position: sticky;
      z-index: 3;
    }

    .sticky-row-1 {
      top: 0;
      height: 20px;
      grid-row: 1;
      background-color: $light;
    }

    .sticky-row-2 {
      top: 20px;
      height: 20px;
      grid-row: 2;
    }

    .sticky-row-3 {
      top: 40px;
      height: 20px;
      grid-row: 3;
    }

    .sticky-col-1 {
      grid-column: 1;
    }

    .sticky-col-1-4 {
      grid-column: 1 / 4;
    }

    .sticky-col-4-7 {
      grid-column: 4 / 7;
    }

    .sticky-col-2 {
      grid-column: 2;
    }

    .sticky-col-3 {
      grid-column: 3;
    }

    .sticky-col-4 {
      grid-column: 4;
    }

    .sticky-col-5 {
      grid-column: 5;
    }

    .sticky-col-6 {
      grid-column: 6;
    }

    .sticky-column-extra-id {
      position: sticky;
      overflow: hidden;
      z-index: 6;
      transition:
        max-width 0.5s ease-in-out,
        color 0.5s;
      min-width: 1px;
      left: 241px;
    }

    .sticky-column-extra-prov {
      position: sticky;
      overflow: hidden;
      z-index: 6;
      transition:
        max-width 0.5s ease-in-out,
        color 0.5s;
      min-width: 1px;
      left: 361px;
    }

    .extra-column {
      max-width: 200px;

      & > span {
        transition: opacity 1s ease-in-out;
      }

      &.collapsed {
        max-width: 1px;
        margin-left: -1px;
        z-index: -1;
        padding: 0;
        color: transparent;

        & > span {
          opacity: 0;
        }
      }
    }

    .header-sticky-background {
      grid-row: 1 / 4;
      background: $light;
      z-index: 2;
    }

    & > div {
      background: $proda-white;
    }

    .grid-filter-container:not(
        .sticky-column-extra-id,
        .sticky-column-extra-prov
      ) {
      min-width: 60px;
    }

    .grid-filter-container {
      & > div {
        position: relative;
        flex-grow: 1;
        align-self: stretch;
      }

      .grid-filter-input {
        width: 100%;
        height: 18px;
        position: absolute;
        inset: 0;
      }
    }

    .grid-button {
      display: flex;
      place-items: center;
      height: 17px;
      padding: 0 4px;
      white-space: nowrap;
      background: $light;
      border: solid 1px $primary-color;
      border-radius: 4px;
      font-size: 12px;
      margin-left: 2px;
      cursor: pointer;

      .selected {
        font-weight: bold;
      }
    }

    .grid-year {
      padding: 0 4px;
      background: $primary-color;
      color: $text-color-light;
      z-index: 3;
    }

    .grid-month-quarter {
      height: 100%;
      width: 100%;
      min-width: 16px;
      place-self: center;
      background-image: linear-gradient(
        to bottom,
        $proda-white,
        $proda-white,
        $proda-white
      );
      transition:
        min-width 1.5s ease-out,
        opacity 1s;
      display: flex;
      flex-direction: row;
      z-index: 3;

      & > div {
        width: 0;
        flex-basis: 16px;
        overflow: hidden;
        writing-mode: vertical-rl;
        transform: rotate(-180deg) translate(0, 0);
      }
    }

    .grid-cell,
    .grid-month-quarter,
    .grid-year,
    .grid-database,
    .grid-portfolio,
    .grid-project {
      align-items: center;
      border-bottom: 1px solid $row-border;
      border-right: 1px solid $row-border;
      justify-content: center;
      display: flex;
      text-align: center;

      span {
        overflow: hidden;
      }
    }

    .grid-property,
    .grid-user-id,
    .grid-data-provider {
      align-items: center;
      border-bottom: 1px solid $row-border;
      border-right: 1px solid $row-border;
      display: flex;
      text-align: left;
      text-wrap: nowrap;

      // padding: 0 2px;
    }

    .grid-database,
    .grid-portfolio,
    .grid-project {
      display: flex;
      align-items: center;
      background: $secondary-color;
      padding: 2px;

      & > span {
        position: sticky;
        bottom: 0;
        top: 60px;
        padding: 10px 0;
      }
    }

    .grid-database {
      writing-mode: vertical-rl;

      & > span {
        transform: rotate(-180deg);
        transform-origin: 50% 50%;
        line-height: 18px;
        text-overflow: ellipsis;
        flex-grow: 1;

        .db-name {
          font-style: italic;
        }
      }
    }

    .grid-portfolio {
      writing-mode: vertical-rl;

      & > span {
        transform: rotate(-180deg);
        transform-origin: 50% 50%;
        line-height: 18px;
        text-overflow: ellipsis;
        flex-grow: 1;
      }
    }

    .grid-project {
      & > span {
        flex-grow: 1;
        line-height: 20px;
      }
    }

    .grid-property {
      min-width: 200px;
      max-width: 350px;
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: $proda-white;
      text-align: left;
      padding-left: 2px;
      min-height: 18px;
    }

    .grid-user-id,
    .grid-data-provider {
      overflow: hidden;
      z-index: 3;
      transition: max-width 0.5s ease-in-out;
    }

    .grid-cell {
      a {
        margin-left: 1px;
        margin-right: 1px;
        z-index: 2;
        line-height: 7px;
        font-size: 14px;
        width: 10px;
        height: 8px;
        color: $primary-color;

        &:hover {
          font-size: 16px;
        }

        &:active {
          color: $primary-color;
        }
      }
    }

    .grid-file {
      background-color: $success-bg;
      border-radius: 10px;
      z-index: 1;
      width: calc(100% - 1px);
      height: calc(100% - 1px);
    }
  }
}
