.file-management-page {
  .ag-row .ag-checkbox-input-wrapper {
    &.ag-checked {
      background-color: $proda-neon-blue;

      &::after {
        padding: 0;
        color: white;
      }
    }
  }
  .ag-row-level--1 [col-id='checkbox'] .ag-cell-wrapper {
    display: none !important;
  }

  .ag-header-cell[col-id='checkbox'] .ag-checkbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .ag-checkbox-input-wrapper {
      &.ag-checked {
        &::after {
          padding: 0;
          color: $proda-neon-blue;
        }
      }
    }
  }

  .ag-header-cell[col-id='checkbox'] .ag-header-cell-menu-button {
    display: none;
  }
}

.extraction-detail-renderer {
  font-variation-settings: initial;
  max-width: 1200px;

  .uikit-checkbox {
    .uikit-icon {
      align-items: center;
      justify-content: center;
    }

    .uikit-checkbox__input:checked ~ div {
      .uikit-icon {
        display: flex;
      }
    }
  }
}
