@import '_helpers';

id-row-table > div {
  height: 100%;
  overflow: auto;
  width: 100%;
}

id-row-table {
  .ag-header {
    .ag-cell-label-container {
      display: block;
      height: 100%;
    }

    .ag-header-cell-text {
      @include clamp-ag-header(5);
    }

    .ag-header-cell {
      background-color: $primary-color;
      border-left: none;
      border-top-color: $proda-white;
      border-top-width: 1px;
      color: $text-color-light;
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      padding-left: 9px;
      padding-right: 9px;
      padding-top: 0;

      &:not(.ag-column-resizing)
        + .ag-header-cell:not(.ag-column-hover, .ag-header-cell-moving):hover {
        background-color: $primary-color;
      }
    }

    .ag-header-cell-label {
      align-items: start;
      display: flex;
      justify-content: space-between;
      height: 100%;
    }

    .ag-header-cell-menu-button {
      width: 0;
    }

    .ag-header-container {
      background: rgb(189 195 199 / 50%);
    }

    .ag-header-icon {
      position: absolute;
      right: 0;
    }

    .ag-header-label-icon {
      color: $text-color-light;

      .ag-icon {
        padding-right: 3px;
      }
    }

    .ag-icon {
      color: $text-color-light;
    }

    .ag-sort-indicator-container {
      .ag-sort-order {
        order: 2;
        padding-left: 0;
      }

      .ag-sort-ascending-icon {
        order: 1;
      }
    }
  }

  .ag-cell.ag-cell.ag-cell {
    border-top: none;
    border-bottom: none;
    border-left: none;
    border-right: 1px solid $cell-border;
    padding-left: 4px;
    padding-right: 4px;
  }

  .ag-row.ag-row-hover::before {
    display: none;
  }

  .ag-row.ag-row-selected {
    background-color: $proda-white;

    &::before {
      background-color: transparent;
    }
  }

  .ag-row:not(.ag-row-selected) {
    background-color: $disabled-bg;
  }

  .ag-row:not(.ag-row-selected) .ag-cell {
    color: $text-color-dark;
  }

  .ag-row.ag-row.ag-row {
    border-bottom: 1px solid $row-border;
  }

  .ag-row.ag-row.ag-row.ag-row-hover {
    background-color: $secondary-color;
  }
}
