table-bounds-table {
  > div {
    height: 100%;
    overflow: auto;
    width: 100%;
  }

  div.ag-header {
    border-bottom: 1px dotted $row-border !important;
  }

  div.ag-cell.header-bounds,
  div.ag-header-cell.table-header-bounds,
  .ag-cell-range-selected:not(.ag-cell-focus).header-bounds {
    background-color: $secondary-color !important;
    font-weight: bold;
  }

  div.ag-cell.body-bounds,
  .ag-cell-range-selected:not(.ag-cell-focus).body-bounds {
    background-color: $proda-white-130 !important;
  }

  .row-header,
  div.ag-pinned-left-header {
    background-color: $light;
    // TODO: not sure about this
    border-right: 1px dotted $row-border !important;
  }

  div.ag-center-cols-clipper {
    border-left: 5px solid $proda-white;
  }

  div.ag-header.ag-pivot-off > :not(ag-pinned-left-header) {
    border-left: 5px solid $proda-white;
  }

  .ag-pinned-left-header {
    border: none;
  }

  // TODO: cell selection makes inner borders almost invisible.
  .ag-cell.ag-cell.ag-cell:not(.ag-cell-focus, .ag-cell-range-selected) {
    border-left: 1px dotted $row-border;
  }

  .ag-cell.ag-cell.ag-cell {
    padding-left: 4px;
    padding-right: 4px;
  }

  .ag-row.ag-row.ag-row {
    border: none;
    border-bottom: 1px solid $row-border;
  }
}
