.ColAssign {
  margin-top: 15px;
  padding-bottom: 1em;

  .search-box {
    background-color: $proda-white;

    #search-box-input {
      width: 230px;
    }
  }

  li.list {
    &.list-group-item {
      font-size: 0.8rem;
      padding-left: 2em;
    }
  }

  &__header {
    color: $text-color-dark;
    font-weight: bold;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 0;
    padding-bottom: 5px;

    &--small {
      font-size: 12px;

      strong {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    * {
      margin: 0;
    }
  }

  &__body {
    background-color: $proda-white;
    box-shadow: $shadow;
    border-radius: 11px;

    * {
      margin: 0;
    }

    &__separator {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.3em 0.8em;
      font-size: 0.9rem;
      color: $text-color-dark;
      text-transform: uppercase;

      .revert_button {
        background-color: $proda-white;
        border-radius: 3px;
        border: 1px solid $primary-color;
        color: $text-color-dark;
        cursor: pointer;
        font-size: 12px;
        font-weight: bold;
        margin-left: 2px;
        outline: none;
        padding: 4px 8px;
        white-space: nowrap;
      }
    }

    &__alternatives {
      p {
        font-family: inherit;
        font-size: 12px;
      }

      ul {
        list-style: none;
      }

      li {
        display: inline;
      }

      a {
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
      }

      li::after {
        content: ', ';
      }

      li:last-child::after {
        content: '';
      }
    }

    &__bottom {
      border-top: 1px solid $proda-white-130;
      text-align: center;
      width: 100%;

      button {
        border: none;
        background: transparent;
        color: $proda-red-120;
        padding: 1em 0;
        font-size: 0.9rem;
        cursor: pointer;
        font-weight: bold;
        width: 100%;
      }
    }

    &--top {
      background: $primary-color;
      border-radius: 11px 11px 0 0;
      font-size: 12px;
      padding: 0.5em 0.8em;
      min-height: 130px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      color: $text-color-light;

      a:link {
        color: rgb(118 223 255);
      }

      a:visited {
        color: rgb(248 118 255);
      }

      a:hover {
        color: rgb(252 188 255);
      }

      h2,
      h3,
      h4 {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 1.3rem;
      }

      &.prediction {
        background: $primary-color;

        &-ignored {
          background: $cell-header-ignored-proda-bg;
          color: $text-color-dark;
        }
      }

      &.user {
        background: $proda-purple;

        &-ignored {
          background: $cell-header-ignored-user-bg;
          color: $text-color-dark;
        }
      }

      &.labelset {
        background: $inferred;
        color: $text-color-dark;

        &-ignored {
          background: $inferred-bg;
          color: $text-color-dark;
        }
      }
    }

    &--content {
      margin-bottom: 0.5rem;

      p {
        max-width: calc(100% - 10px);
      }

      &.reduce-spacing p {
        margin-bottom: 0;
      }
    }
  }
}
